import {ProductId} from "@component/Checkout/useProductCheckout.ts";

export const BUILD_ID = import.meta.env.VITE_BUILD_ID || "development";

export const SERVICE_API_URL = import.meta.env.DEV
	? 'http://localhost:8080/v1'
	: 'https://api.raviolstation.com/v1';

export const SERVICE_API_URL_V2 = import.meta.env.DEV
	? 'http://localhost:8080/v2'
	: 'https://api.raviolstation.com/v2';

export const DEFAULT_REQUEST_TIMEOUT_MS = import.meta.env.DEV
	? 110 * 5 * 1000
	: 110 * 1000;

export const MOCKED_MODE = import.meta.env.DEV && false;
export const MOCKED_MODE_REQUEST_FAKE_TIME = 500;
export const MOCKED_REMAINING_USES = 1;
export const MOCKED_MODE_MAX_CHAPTERS = 5;

export const PRODUCTS = import.meta.env.DEV
	? {
		[ProductId.FIRST]: "b65c5cb3-42f6-4ae0-951c-04ab32fc78a1",
		[ProductId.SECOND]: "a1306504-c7fa-4a15-82ad-f7b8fed505b9",
		[ProductId.THIRD]: "e8a3ef43-16bf-4945-8be7-fdff94fc8631",
		[ProductId.FOURTH]: "d7000d4c-8d87-464e-bfcb-06add9938cfc",
	} : {
		[ProductId.FIRST]: "4915b4e2-43ff-474d-aa32-9fb52e37787e",
		[ProductId.SECOND]: "31b902bc-a5c3-407a-980c-5772a88e9e26",
		[ProductId.THIRD]: "b61cd4c3-c4c0-4e8b-bbea-d007a8f9eca6",
		[ProductId.FOURTH]: "31e53fa9-45a8-4bba-bcfb-0548dc8beae3",
	};

export const GOOGLE_ACCOUNTS_CLIENT_ID = import.meta.env.DEV
	? "237770203067-q332cjm15lmns1ee2gm789vl2jd3uduc.apps.googleusercontent.com"
	: "98769740945-k6i64k172ad6s6eevp686kvvpdkia062.apps.googleusercontent.com";

export const MS_IDENTITY_CLIENT_ID = import.meta.env.DEV
	? "6da584b3-40f2-40cf-a2f4-fbccba9591bd"
	: "f438e1e7-820d-4568-8aa5-23ae52e9a5f6";

export const OAUTH_REDIRECT_URL = import.meta.env.DEV
	? "http://localhost:5173/accounts/register"
	: "https://storyscapeai.app/accounts/register";
