import React, {ReactNode, useCallback, useState} from "react";
import {defaultReadingSettings, LOCAL_STORAGE_KEY} from "@app/providers/reading-settings/index.ts";

export type ReadingSettingsState = typeof defaultReadingSettings;

type ReadingSettingsContextType = [ReadingSettingsState, React.Dispatch<React.SetStateAction<ReadingSettingsState>>];

export const ReadingSettingsContext = React.createContext<ReadingSettingsContextType|undefined>(undefined);

export function ReadingSettingsProvider({children}: { children?: ReactNode }) {
	const [readingSettings, setReadingSettings] = useState<ReadingSettingsState>(() => {
		const item = localStorage.getItem(LOCAL_STORAGE_KEY);

		if (!item) {
			localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(defaultReadingSettings));

			return defaultReadingSettings;
		}

		return JSON.parse(item || "{}");
	});

	const updateSettings = useCallback((newSettings: React.SetStateAction<ReadingSettingsState>) => {
		setReadingSettings((prevSettings) => {
			const updatedSettings = typeof newSettings === 'function' ? newSettings(prevSettings) : newSettings;

			localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedSettings));

			return updatedSettings;
		});
	}, []);

	return (
		<ReadingSettingsContext.Provider value={[readingSettings, updateSettings]}>
			{children}
		</ReadingSettingsContext.Provider>
	);
}
