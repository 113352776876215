import React, {createContext, Dispatch, useEffect, useReducer,} from 'react';
import {reducer, SessionAction, SessionContextState} from "@app/providers/session/reducer.ts";
import {getSession} from "@app/providers/session/db.ts";
import {fireCriticalError, hydrateSession} from "@app/providers/session/dispatchers.ts";

export type SessionContextType = {
	state: SessionContextState;
	dispatch: Dispatch<SessionAction>;
};

export const SessionContext = createContext<SessionContextType | undefined>(undefined);

export function SessionContextProvider({children, sessionId}: {
	children: React.ReactNode;
	sessionId: string;
}) {
	const [state, dispatch] = useReducer(reducer, {
		id: sessionId,
		title: "",
		chapters: [],
		isLastChapter: false,
		isFinished: false,
		isLoading: false,
		isHydrated: false,
		error: null,
	});

	useEffect(() => {
		getSession(sessionId).then(session => {
			if (!session) {
				fireCriticalError(dispatch, {
					title: "404 Not Found",
					description: "We couldn't found this story",
				});

				return;
			}

			hydrateSession(dispatch, session);
		})
	}, [sessionId]);

	return (
		<SessionContext.Provider value={{state, dispatch}}>
			{children}
		</SessionContext.Provider>
	);
}
