import React, {useEffect} from 'react';

import {useLoaderData, useNavigate} from "react-router-dom";
import {ReadingSettingsProvider} from "@app/providers/reading-settings/provider.tsx";
import {SessionContextProvider} from "@app/providers/session/provider.tsx";
import {SessionComponent} from "./components/SessionComponent";
import {EmptyContent} from "@component/EmptyContent";
import ErrorImg from "@images/png/512/🫤.png";
import {useUpdateAccessInfo} from "@app/providers/access-info";
import {showToast} from "@app/providers/toast";

export function Session(): React.JSX.Element {
	const response = useLoaderData() as string | { message: string };
	const navigate = useNavigate();

	// why do we check right on session load? answer: because access-info is a context provider. Need to make it a store.
	// 1 - if we're here because a new story just started, we've checked already before fetching it
	// 2 - if we're here from a saved story, checking access info is unnecessary
	// todo: make access info a persistent store, then update access info from the new-story route's action
	//   and remove this from here.
	const updateAccessInfo = useUpdateAccessInfo();

	useEffect(() => {
		updateAccessInfo().catch(error => {
			showToast({
				title: 'Error checking remaining coins',
				description: error.message
			});
		});
	}, [updateAccessInfo]);

	if (typeof response === "object" && "message" in response) {
		return (
			<EmptyContent
				title={"An error occurred"}
				description={response.message}
				imgSrc={ErrorImg}
			>
				<button
					className={"secondaryButton"}
					onClick={() => navigate(-1)}
				>
					Go back
				</button>

				<button onClick={() => navigate(0)}>Try again</button>
			</EmptyContent>
		);
	}

	return (
		<ReadingSettingsProvider>
			<SessionContextProvider sessionId={response as string}>
				<SessionComponent/>
			</SessionContextProvider>
		</ReadingSettingsProvider>
	);
}
