import React from "react";
import {Dialog} from "@component/ui/Dialog";
import styles from "./styles.module.css"

interface NsfwDisclaimerDialogProps {
	setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onClosedCallback: (accepted: boolean) => void;
	isOpen: boolean;
	triggerElement?: React.ReactNode;
}

export function NsfwDisclaimerDialog(props: NsfwDisclaimerDialogProps) {
	const {isOpen, setIsDialogOpen, onClosedCallback} = props;

	const onClose = () => {
		onClosedCallback(false);
		setIsDialogOpen(false);
	};

	const onAccept = () => {
		onClosedCallback(true);
		setIsDialogOpen(false);
	};

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			triggerElement={props.triggerElement}
			showCloseButton={false}
			width={"650px"}
			title={"🚨 Important information about sensitive content"}
		>
			<div className={styles.nsfwDisclaimerMessage}>
				<p>
					Please be aware that we need to follow OpenAI's usage policies, which prohibit some types of content, such as:
				</p>

				<ul>
					<li>Extremely violent or sexual content.</li>
					<li>Content that promotes hate speech, discrimination, or harm to individuals or groups.</li>
				</ul>

				<p>
					We understand that this may limit your creative freedom, but we appreciate your understanding and cooperation.
				</p>

				<p>
					For more information on prohibited content, please refer to {" "} <a
					href="https://openai.com/policies/usage-policies">OpenAI's usage policies</a>.
				</p>

				<details className={styles.detailsBox}>
					<summary><b>What happens when my story prompt contains prohibited content?</b></summary>
					<p>
						Our system will check if your story prompt contains any of the prohibited content listed above, and if it
						does, we'll let you know and the story won't be generated.
					</p>

					<p>You can then modify the story prompt and try again.</p>
				</details>
			</div>

			<div className={styles.nsfwDisclaimerFooter}>
				<button className="secondaryButton" onClick={onClose}>Cancel</button>

				<button onClick={onAccept}>I understand</button>
			</div>
		</Dialog>
	);
}
