export const SHOW_TOAST_EVENT_ID = 'storyscapeai-toast-event';

export interface ToastPayload {
	title: string;
	description: string;
}

export function showToast(payload: ToastPayload) {
	const event = new CustomEvent(SHOW_TOAST_EVENT_ID, { detail: payload });

	window.dispatchEvent(event);
}
