import React, {useEffect, useState} from "react";

const LOCAL_STORAGE_KEY = 'isLightMode';

export function useLightMode(persist = true): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
	const [isLightMode, setIsLightMode] = useState<boolean>(() => {
		return Boolean((localStorage.getItem(LOCAL_STORAGE_KEY) ?? 'false') === 'true')
	});

	useEffect(() => {
		document.documentElement.classList.toggle('dark', !isLightMode);

		if (persist) {
			localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(isLightMode));
		}
	}, [isLightMode, persist]);

	return [isLightMode, setIsLightMode];
}
