import React from "react";
import {useLoaderData, useNavigate, useRevalidator} from "react-router-dom";
import {SessionState} from "@app/providers/session/dtos.ts";
import styles from "./styles.module.css";
import {SectionCont, SectionMainContent, SectionTitle} from "@component/Layout";
import {EmptyContent} from "@component/EmptyContent";
import EmptyContentImage from "@images/png/512/🫤.png";
import {deleteSession} from "@app/providers/session/db.ts";

export function Stories(): React.JSX.Element {
	const sessions = useLoaderData() as SessionState[];
	const navigate = useNavigate();
	const revalidator = useRevalidator();

	return (
		<SectionCont>
			<SectionMainContent>
				<SectionTitle
					title={"Stories"}
					showGoBackButton={true}
				/>

				<div>
					<p className={styles.sectionDescription}>
						<strong>Here, you can find your previously <em>completed</em> stories.</strong>
					</p>

					<p className={styles.sectionDescription}>
						Please note that these stories are only saved on this device.
					</p>
				</div>

				{!sessions?.length && (
					<EmptyContent
						title={"No stories"}
						description={"You haven't completed any stories yet"}
						imgSrc={EmptyContentImage}
					/>
				)}

				{!!sessions?.length && (
					<ul className={styles.stories}>
						{
							sessions?.map(session => {
								const image = session.chapters[0]?.images?.[0];

								return (
									<li key={session.id}>
										<div className={styles.storyItem}>
											<img src={image?.url} alt={image?.caption}/>

											<p className={styles.storyTitle}>{session.title}</p>
											<p className={styles.firstParagraph}>by {"StoryScape AI"}</p>
										</div>

										<div className={styles.storyItemControlButtons}>
											<button
												className={"secondaryButton" + " " + styles.deleteButton}
												onClick={() => {
													deleteSession(session.id).then(() => {
														revalidator.revalidate();
													});
												}}
												aria-label={"Delete story"}
											>
												Delete
											</button>

											<button
												className={styles.openButton}
												onClick={() => {
													navigate(`/story/${session.id}`);
												}}
												aria-label={"Open story"}
											>
												✨ Open
											</button>
										</div>
									</li>
								);
							})
						}
					</ul>
				)}

				{!!sessions.length && (
					<button
						className={"secondaryButton " + styles.backButton}
						onClick={() => navigate(-1)}
					>
						Go back
					</button>
				)}
			</SectionMainContent>
		</SectionCont>
	);
}
