import {SessionContextType} from './provider.tsx';
import {getNextChapter, getRegenNewImage} from '../../apis/sessions.ts';
import {AccessInfoContextType} from "@app/providers/access-info/provider.tsx";
import Analytics, {APP_EVENTS} from "@app/analytics.ts";
import {showToast, ToastPayload} from "@app/providers/toast";
import {Dispatch} from "react";
import {SessionAction, SessionContextState} from "@app/providers/session/reducer.ts";

export function fireCriticalError(dispatch: Dispatch<SessionAction>, payload: ToastPayload): void {
	dispatch({
		type: 'CRITICAL_ERROR',
		payload,
	});
}

export function hydrateSession(dispatch: Dispatch<SessionAction>, state: SessionContextState): void {
	dispatch({
		type: 'SESSION_HYDRATED',
		payload: state,
	});
}

export async function fetchNextChapter(
	dispatch: SessionContextType['dispatch'],
	accessInfoDispatch: AccessInfoContextType['dispatch'],
	session_id: string,
	player_choice?: number,
	player_idea?: string
) {
	dispatch({type: 'REQUEST_NEXT_CHAPTER'});

	Analytics.logEvent(APP_EVENTS.NEXT_CHAPTER, {
		is_user_idea: Boolean(player_idea),
	});

	try {
		const response = await getNextChapter(
			{
				session_id,
				...(player_choice !== undefined && {player_choice}),
				...(player_idea && {player_idea}),
			}
		);

		dispatch({
			type: 'NEXT_CHAPTER_LOADED',
			payload: {
				chapterResponse: response,
				player_choice,
				player_idea,
			},
		});

		accessInfoDispatch({
			type: 'UPDATE_ACCESS_INFO',
			payload: {
				remaining: response.remaining_uses,
				next_try_in_secs: -1,
			},
		});

		return true;
	} catch (error) {
		showToast({
			title: "An error occurred",
			description: "We couldn't get the next chapter"
		});

		dispatch({type: 'ERROR_GETTING_NEXT_CHAPTER'});
	}

	return false;
}

export interface RegenChapterImageOptions {
	session_id: string;
	chapter_index: number;
	image_tags?: string;
}

export async function regenChapterImage(
	dispatch: SessionContextType['dispatch'],
	accessInfoDispatch: AccessInfoContextType['dispatch'],
	options: RegenChapterImageOptions,
) {
	const {session_id, chapter_index, image_tags} = options;

	dispatch({type: 'REGEN_IMAGE'});

	Analytics.logEvent(APP_EVENTS.REGEN_IMAGE);

	try {
		const response = await getRegenNewImage({
			session_id,
			chapter_index,
			new_image_tags: image_tags
		});

		if (response.new_image) {
			dispatch({
				type: 'REGEN_IMAGE_RECEIVED',
				payload: {
					image: response.new_image,
					chapterIndex: chapter_index
				},
			});
		} else {
			showToast({
				title: "An error occurred",
				description: "We couldn't generate a new image"
			});

			dispatch({ type: 'ERROR_REGEN_IMAGE'});
		}

		accessInfoDispatch({
			type: 'UPDATE_ACCESS_INFO',
			payload: {
				remaining: response.remaining_uses,
				next_try_in_secs: -1,
			},
		});
	} catch (error) {
		showToast({
			title: "An error occurred",
			description: "We couldn't generate a new image"
		});

		dispatch({ type: 'ERROR_REGEN_IMAGE'});
	}
}
