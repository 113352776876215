import * as Server from '../../apis/sessions.models.ts';
import Tokenizer from "sbd";
import smartquotes from "smartquotes";
import {ToastPayload} from "@app/providers/toast";

export interface ChapterDTO extends Server.ChapterResponse {
	playerChoice?: string;
	textParagraphs: string[];
	imagePositionBetweenParagraphs: number;
	selectedImageIndex: number;
}

export interface SessionDTO {
	id: string;
	title: string;
	chapters: ChapterDTO[];
	isLastChapter: boolean;
}

export interface SessionState extends SessionDTO {
	isLoading: boolean;
	isFinished: boolean;
	isHydrated: boolean;
	error: ToastPayload | null;
}

export function createChapterDTO(chapter: Server.ChapterResponse): ChapterDTO {
	const formattedChapterText = formatChapterText(chapter.text);
	const textParagraphs =  Tokenizer.sentences(formattedChapterText);

	const MIN_PARAGRAPH_INDEX = 2;
	const index = Math.min(MIN_PARAGRAPH_INDEX + Math.floor(Math.random() * textParagraphs.length/2), textParagraphs.length - 1);

	return {
		...chapter,
		textParagraphs: textParagraphs,
		imagePositionBetweenParagraphs: index,
		selectedImageIndex: 0
	};
}

export function formatChapterText(text: string) {
	let textWithoutInitialQuotes = text;

	if (text.startsWith("\"") && text.endsWith("\"")) {
		textWithoutInitialQuotes = text.substring(1, text.length - 1);
	}

	return smartquotes.string(textWithoutInitialQuotes);
}
