import React, {useCallback} from "react";
import {IconButton} from "@component/ui/Button";
import {useLightMode} from "./useLightMode.tsx";

export function LightModeButton({visible = true}: {visible?: boolean}): React.JSX.Element | null {
	const [isLightMode, setIsLightMode] = useLightMode();

	const onLightModeClick = useCallback(() => {
		setIsLightMode(!isLightMode);
	}, [isLightMode, setIsLightMode]);

	if (!visible) return null;

	return (
		<IconButton
			label={`Turn on ${!isLightMode ? "light" : "dark"} mode`}
			onClick={onLightModeClick}
			symbolId={isLightMode ? "light_mode" : "dark_mode"}
		/>
	);
}
