import styles from './style.module.css';
import {NavLinkProps, useNavigate} from "react-router-dom";
import React from "react";
import {Icon, IconProps} from "@component/ui/Icon/Icon.tsx";

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	symbolId?: string;
	active?: boolean;
	fontSizeRem?: number;
	offset?: IconProps["offset"];
	label: string;
	iconProps?: IconProps;
}

export const IconButton = React.forwardRef(
	(props: IconButtonProps, forwardedRef: React.ForwardedRef<HTMLButtonElement>) => {
		const {
			symbolId,
			active,
			fontSizeRem,
			offset,
			label,
			title,
			iconProps,
			...rest
		} = props;

		return (
			<button
				{...rest}
				ref={forwardedRef}
				className={
					[
						styles.iconButton,
						active ? styles.iconButtonActive : "",
						rest.className || ""
					].join(" ")
				}
				aria-label={label}
				title={title ? title : label}
			>
				<Icon fontSizeRem={fontSizeRem} offset={offset} {...(iconProps || {
					symbolId
				})}/>
			</button>
		);
	});

export interface MenuButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	iconProps?: IconProps;
}

export const MenuButton = React.forwardRef(
	(props: MenuButtonProps, forwardedRef: React.ForwardedRef<HTMLButtonElement>) => {
		const {
			iconProps,
			className = "",
			children,
			...rest
		} = props;

		return (
			<button {...rest} ref={forwardedRef} className={
				[
					styles.textIconButton,
					!iconProps ? styles.textIconButtonNoIcon : "",
					className
				].join(" ")
			}>
				{iconProps && <Icon
					{...iconProps}
				/>}

				<span className={styles.textIconButtonText}>
					{children}
				</span>
			</button>
		);
	});

export interface LinkButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	to: string;
	customStyle?: string;
	variant?: "primary" | "secondary" | "menuItem";
}

export const LinkButton = React.forwardRef(
	(props: LinkButtonProps & NavLinkProps, forwardedRef: React.ForwardedRef<HTMLButtonElement>) => {
		const {
			to,
			variant,
			customStyle,
			replace = false,
			...rest
		} = props;

		const navigate = useNavigate();

		const clickHandler = () => {
			navigate(to, {replace: replace})
		};

		return (
			<button {...rest} ref={forwardedRef} onClick={clickHandler} className={
				[
					variant === "secondary" ? "secondaryButton" : "",
					variant === "menuItem" ? styles.textIconButton : "",
					customStyle
				].join(" ")
			}>
				{rest.children}
			</button>
		);
	});
