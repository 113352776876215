import styles from "./styles.module.css";
import CoinsImg from "@images/emojis/coin_3d.png";
import Scroll from "@images/emojis/scroll_3d.png"
import Scrolls from "@images/emojis/scrolls_3d.png"
import Book from "@images/emojis/orange_book_3d.png"
import Medal from "@images/emojis/1st_place_medal_3d.png"
import Fire from "@images/png/512/🔥.png"
import {ProductId, useProductCheckout} from "./useProductCheckout.ts";
import {Icon} from "@component/ui/Icon/Icon.tsx";

const products = [
	{
		coins: 10,
		productId: ProductId.FIRST,
		price: "$0.50",
		imageUrl: Scroll,
		description: "Enough to generate up to 10 chapters or 2 short stories",
	},
	{
		coins: 50,
		productId: ProductId.SECOND,
		price: "$2.00",
		discount: {
			percentage: "20%",
			originalPrice: "$2.50"
		},
		primary: true,
		extra: {
			title: "Most popular",
			imageUrl: Medal,
		},
		imageUrl: Scrolls,
		description: "Enough to generate up to 50 chapters or 10 short stories",
	},
	{
		coins: 250,
		productId: ProductId.FOURTH,
		price: "$8.00",
		discount: {
			percentage: "36%",
			originalPrice: "$12.50"
		},
		primary: false,
		extra: {
			title: "Best value",
			imageUrl: Fire,
		},
		imageUrl: Book,
		description: "Enough to generate up to 250 chapters or 50 short stories",
	},
];

interface CheckoutProps {
	onSuccess?: () => void;
}

export function Checkout({onSuccess}: CheckoutProps) {
	const {openProductCheckout, isLoading} = useProductCheckout(onSuccess);

	return (
		<section className={styles.cont}>
			<ul className={styles.list}>
				{
					products.map((product, i) => (
						<li key={i}>
							<div className={styles.option + (product.primary ? " " + styles.primary : "")}>
								<div className={styles.header}>
									<img src={CoinsImg} alt=""/>
									<span>x {product.coins}</span>

									{product.extra && (
										<div className={styles.extra}>
											<span>{product.extra.title}</span>
											<img src={product.extra.imageUrl} alt=""/>
										</div>
									)}
								</div>

								<p className={styles.description}>
									{product.description}
								</p>

								<img src={product.imageUrl} alt="" className={styles.image}/>

								<div className={styles.price}>
									{product.discount && (
										<span className={styles.strikethrough}>
										{product.discount.originalPrice}
									</span>
									)}

									<p className={styles.finalPrice}>
										{product.price}
									</p>

									{product.discount && (
										<span className={styles.discountPercentage}>
										{`(${product.discount.percentage} OFF)`}
									</span>
									)}
								</div>

								<button
									className={!product.primary ? "secondaryButton" : ""}
									disabled={isLoading}
									onClick={() => openProductCheckout(product.productId)}
								>
									{`Buy ${product.coins} coins (${product.price})`}
								</button>
							</div>
						</li>
					))
				}
			</ul>

			<p className={styles.paymentInfo}>
				<span title={"Safe checkout secured by SSL encryption."}>
					<Icon symbolId={"encrypted"} fontSizeRem={1.5} offset={{x: 3, y: 0}}/>
				</span>
				<span>{"Prices are in USD. Payments are securely processed by LemonSqueezy."}</span>
			</p>
		</section>
	);
}
