import {useEffect, useState} from "react";
import NewStoryButtonImg1 from "@images/emojis/biting_lip_3d.png";
import NewStoryButtonImg2 from "@images/emojis/exploding_head_3d.png";
import NewStoryButtonImg3 from "@images/emojis/ghost_3d.png";
import NewStoryButtonImg4 from "@images/emojis/anxious_face_with_sweat_3d.png";
import NewStoryButtonImg5 from "@images/emojis/crossed_swords_3d.png";
import NewStoryButtonImg6 from "@images/emojis/star-struck_3d.png";
import NewStoryButtonImg7 from "@images/emojis/sparkling_heart_3d.png";
import NewStoryButtonImg8 from "@images/emojis/robot_3d.png";
import {LinkButton} from "@component/ui/Button";
import styles from "./styles.module.css";

export function NewStoryButtonLink({label, customClassName, to = "/new"}: {
	label?: string,
	customClassName?: string,
	to?: string
}) {
	const [newStoryButtonImgSrc, setNewStoryButtonImgSrc] = useState(NewStoryButtonImg1)

	useEffect(() => {
		const images = [
			NewStoryButtonImg1,
			NewStoryButtonImg2,
			NewStoryButtonImg3,
			NewStoryButtonImg4,
			NewStoryButtonImg5,
			NewStoryButtonImg6,
			NewStoryButtonImg7,
			NewStoryButtonImg8,
		];

		let imageIndex = 0;

		const interval = setInterval(() => {
			imageIndex = (imageIndex + 1) % images.length;

			setNewStoryButtonImgSrc(images[imageIndex]);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<LinkButton to={to} variant="primary" customStyle={styles.newStoryButton + " " + (customClassName || "")}>
			<span>{label ?? "New story"}</span>
			<img src={newStoryButtonImgSrc} alt={""}/>
		</LinkButton>
	);
}
