import {useEffect} from "react";

function setupYBug() {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window.ybug_settings = {
		id: "8hgda1fsxqmhcf6byrd2"
	};

	const ybug = document.createElement('script');

	ybug.type = 'text/javascript';
	ybug.async = true;
	ybug.id = 'ybug-script';
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	ybug.src = `https://widget.ybug.io/button/${window.ybug_settings.id}.js`;

	document.body.append(ybug);
}

function removeYBug() {
	const ybugFrame = document.getElementById('__ybug-launcher');
	const ybugScript = document.getElementById('ybug-script');

	if (ybugFrame) {
		ybugFrame.remove();
	}

	if (ybugScript) {
		ybugScript.remove();
	}
}

export function useYBugWidget(isAuthenticated: boolean) {
	useEffect(() => {
		if (isAuthenticated) {
			setupYBug();
		} else {
			removeYBug();
		}
	}, [isAuthenticated]);
}
