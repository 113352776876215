import React from "react";
import style from "./style.module.css";
import {Link} from "react-router-dom";
import {LinkButton} from "@component/ui/Button";

export function AuthCTAButtons(): React.JSX.Element {
	return (
		<div className={style.userBoxButton}>
			<Link to="/accounts/login" className={style.loginButton}>Log in</Link>
			<LinkButton to="/accounts/register" customStyle={style.signupButton}>
				Sign up
			</LinkButton>
		</div>
	);
}
