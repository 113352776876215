import React, {useCallback, useEffect, useState,} from 'react';
import {Toast} from "@component/ui/Toast/Toast.tsx";
import ReactDOM from "react-dom";
import {SHOW_TOAST_EVENT_ID, ToastPayload} from "@app/providers/toast/index.ts";

export function GlobalToast() {
	const [payload, setPayload] = useState<ToastPayload>();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const handleToastEvent = (event: CustomEvent<ToastPayload>) => {
			setPayload(event.detail);
			setIsOpen(true);
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		window.addEventListener<any>(SHOW_TOAST_EVENT_ID, handleToastEvent);

		return () => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			window.removeEventListener<any>(SHOW_TOAST_EVENT_ID, handleToastEvent);
		};
	}, []);

	const onOpenChange = useCallback((isOpen: boolean) => {
		if (!isOpen) setIsOpen(false);
	}, []);

	return ReactDOM.createPortal((
		<Toast
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				title={payload?.title}
				description={payload?.description}
		/>
		), document.getElementById("toast-portal")!
	);
}
