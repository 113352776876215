import {
	DEFAULT_REQUEST_TIMEOUT_MS,
	MOCKED_MODE,
	MOCKED_MODE_REQUEST_FAKE_TIME,
	MOCKED_REMAINING_USES,
	SERVICE_API_URL,
	SERVICE_API_URL_V2,
} from '../../config.ts';
import {getNextDesignChapter, startNewDesignSession} from './sessions.mocks.ts';
import {getAccessToken} from "@app/stores/auth";
import {
	CheckAccessResponse,
	NewSessionRequest,
	NewSessionResponse,
	NextChapterRequest,
	NextChapterResponse,
	RegenImageRequest,
	RegenImageResponse
} from "@app/apis/sessions.models.ts";

interface FetchOptions extends RequestInit {
	timeout?: number;
}

export async function fetchWithTimeout(
	resource: RequestInfo,
	options: FetchOptions = {},
	timeout: number = DEFAULT_REQUEST_TIMEOUT_MS,
): Promise<Response> {
	const controller = new AbortController();
	const {signal} = controller;

	const fetchPromise = fetch(resource, {...options, signal});
	const timeoutPromise = new Promise<Response>((_, reject) => {
		setTimeout(() => {
			reject(new Error('Request timed out'));
			controller.abort();
		}, timeout);
	});

	return await Promise.race([fetchPromise, timeoutPromise]);
}

export async function checkAccess(): Promise<CheckAccessResponse> {
	const accessToken = await getAccessToken();

	if (MOCKED_MODE) {
		return await new Promise(resolve =>
			setTimeout(() => {
				resolve({
					remaining: MOCKED_REMAINING_USES,
					next_try_in_secs: null,
				});
			}, 1000),
		);
	}

	const res = await fetchWithTimeout(SERVICE_API_URL + '/check-access', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + accessToken,
		},
	});

	if (res.ok) {
		return await res.json();
	} else {
		throw new Error('An error occurred');
	}
}

export async function newStory(
	request: NewSessionRequest
): Promise<NewSessionResponse> {
	const accessToken = await getAccessToken();

	if (MOCKED_MODE) {
		const session = startNewDesignSession();

		return await new Promise(resolve =>
			setTimeout(() => {
				resolve(session);
			}, MOCKED_MODE_REQUEST_FAKE_TIME),
		);
	}

	const res = await fetchWithTimeout(SERVICE_API_URL_V2 + '/new-story', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + accessToken,
		},
		body: JSON.stringify(request),
	});

	if (res.ok) {
		return await res.json();
	} else {
		throw new Error('Failed to start new story');
	}
}

export async function getNextChapter(
	request: NextChapterRequest
): Promise<NextChapterResponse> {
	const accessToken = await getAccessToken();

	if (MOCKED_MODE) {
		return await new Promise(resolve =>
			setTimeout(() => {
				resolve(getNextDesignChapter());
			}, MOCKED_MODE_REQUEST_FAKE_TIME),
		);
	}

	const res = await fetchWithTimeout(SERVICE_API_URL_V2 + '/next-chapter', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + accessToken,
		},
		body: JSON.stringify(request),
	});

	if (res.ok) {
		return await res.json();
	} else {
		throw new Error('Failed to get next chapter');
	}
}

export async function getRegenNewImage(
	request: RegenImageRequest
): Promise<RegenImageResponse> {
	const accessToken = await getAccessToken();

	if (MOCKED_MODE) {
		return await new Promise(resolve =>
			setTimeout(() => {
				resolve({
					new_image: {
						url: 'https://random.imagecdn.app/255/255' + '?h=' + Math.random(),
						caption: 'an invitation to a party',
						tags: "party, invitation,"+Math.random(),
					},
					remaining_uses: MOCKED_REMAINING_USES,
				});
			}, 500),
		);
	}

	const res = await fetchWithTimeout(SERVICE_API_URL_V2 + '/regen-image', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + accessToken,
		},
		body: JSON.stringify(request),
	});

	if (res.ok) {
		return await res.json();
	} else {
		throw new Error('An error occurred');
	}
}
