import styles from './styles.module.css';

import {LinkButton} from "@component/ui/Button";
import React, {useLayoutEffect, useState} from "react";

import Hmm from "@images/png/512/🫤.png";
import Better from "@images/png/512/😉.png";
import Nerd from "@images/png/512/🤓.png";
import Nice from "@images/png/512/😌.png";
import Parry from "@images/png/512/🦜.png";
import Robot from "@images/png/512/🤖.png";
import PointRight from "@images/png/512/👉️.png";
import Heart from "@images/png/512/transition/Heart with Arrow.png";
import Swords from "@images/emojis/crossed_swords_3d.png";
import Lips from "@images/png/512/transition/Biting Lip.png";
import FloppyDisk from "@images/emojis/floppy_disk_3d.png";
import Sparkles from "@images/emojis/sparkles_3d.png";
import MagicWand from "@images/emojis/magic_wand_3d.png";
import Star from "@images/emojis/star_3d.png";

import img1 from "./images/a.png";
import img2 from "./images/b.png";
import img3 from "./images/c.png";
import img4 from "./images/d.png";
import img5 from "./images/e.png";
import img6 from "./images/f.png";
import {useLightMode} from "../TopBar/useLightMode.tsx";
import {NewStoryButtonLink} from "@component/NewStoryButtonLink";
import {Squiggle} from "./Squiggle.tsx";
import {PreviewBlock} from "./PreviewBlock.tsx";

export function Landing() {
	const [isImageRotating, setIsImageRotating] = useState(false);
	const [_, setIsLightMode] = useLightMode(false);

	useLayoutEffect(() => {
		setIsLightMode(true);
	}, [setIsLightMode]);

	const handleMouseEnter = () => {
		setIsImageRotating(true);
	};

	const handleMouseMove = (e: React.MouseEvent<unknown | HTMLImageElement>) => {
		if (isImageRotating && e.target instanceof HTMLImageElement) {
			const image = e.target;

			const imageRect = image.getBoundingClientRect();
			const xVal = e.clientX - imageRect.left;
			const yVal = e.clientY - imageRect.top;
			const width = imageRect.width;
			const height = imageRect.height;

			const yRotation = 5 * ((xVal - width / 2) / width)
			const xRotation = -5 * ((yVal - height / 2) / height)

			image.style.transform = 'perspective(500px) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)'
		}
	};

	const handleMouseLeave = () => {
		setIsImageRotating(false);
	};

	return (
		<main className={styles.cont}>
			<div className={styles.lines3d}>
				<img src={Heart} alt={""} className={styles.heart}/>
				<img src={Swords} alt={""} className={styles.swords}/>
				<img src={Lips} alt={""} className={styles.lips}/>
			</div>

			<div className={styles.block}>
				<h2 className={styles.title + " " + styles.gradientText}>
					<p>Dive into a world of <strong>limitless creativity</strong> and</p>

					<p className={styles.squiggle}>
						<span className={styles.gradientText}>immersive storytelling</span>
						<Squiggle/>
					</p>
				</h2>

				<div className={styles.shortDescription}>
					<p>
						Escape social media scrolling and become a
						creator with StoryScape AI
					</p>

					<p>
						<strong>
							<span className={styles.highlighted + " " + styles.gradientText}>
								Our platform unlocks the full potential of your imagination
							</span>
						</strong>
					</p>

					<p>Create, customize and share your own unique stories.</p>
				</div>
			</div>

			<div className={styles.blockInline}>
				<NewStoryButtonLink customClassName={styles.CTA} label="Try it now for free"></NewStoryButtonLink>
				<p>{"See what 4000+ others have found - try it today!"}</p>
			</div>

			<div className={styles.playStore}>
				<p>{"Also available on Google Play in the US 🇺🇸"}</p>
				<a
					href="https://play.google.com/store/apps/details?id=com.raviolstation.storyscapeai"
					className="nostyle"
					target="_blank"
				>
					<img
						className="play-button"
						alt="Get it on Google Play"
						width="200px"
						height="auto"
						src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
					/>
				</a>
			</div>

			<section
				className={styles.preview}
				onMouseEnter={handleMouseEnter}
				onMouseMove={handleMouseMove}
				onMouseLeave={handleMouseLeave}
			>
				<PreviewBlock
					emoji={PointRight}
					title={"Start with a few key options"}
					description={"The more details you provide about your story, the better."}
					image={img1}
					imagePosition="right"
				/>

				<PreviewBlock
					emoji={Robot}
					title={"The AI generates your first chapter"}
					description={"You can customize the font size and background for comfortable reading."}
					image={img2}
					imagePosition="left"
				/>

				<PreviewBlock
					emoji={Hmm}
					title={"Image needs adjustment?"}
					description={"Try regenerating it! Let the AI create something new or provide hints."}
					image={img3}
					imagePosition="right"
				/>

				<PreviewBlock
					emoji={Better}
					title={"Looking good!"}
					description={"We're dedicated to improving the quality of images, making sure they stay contextually relevant and consistent throughout the entire story."}
					image={img4}
					imagePosition="left"
				/>

				<PreviewBlock
					emoji={Nerd}
					title={"Unleash Your Creativity!"}
					description={"You control the story, its plots, and the continuation."}
					image={img5}
					imagePosition="right"
				/>

				<PreviewBlock
					emoji={Nice}
					title={"Don't feel like writing?"}
					description={"Each chapter comes with multiple choices to choose from."}
					image={img6}
					imagePosition="left"
				/>
			</section>

			<section className={styles.moreFeatures}>
				<div>
					<strong><p><img className={styles.inlineEmoji} src={Sparkles} alt={""}/> More features coming soon!</p>
					</strong>

					<ul>
						<li><img className={styles.inlineEmoji} src={FloppyDisk} alt={""}/> Save your favorite stories to read
							later.
						</li>
						<li><img className={styles.inlineEmoji} src={MagicWand} alt={""}/> Suggest modifications to chapters.</li>
						<li><img className={styles.inlineEmoji} src={Star} alt={""}/> Share your stories and get rated.</li>
					</ul>
				</div>

				<img src={Parry} alt={""} className={styles.parry}/>
			</section>

			<section className={styles.footer}>
				<div className={styles.shortDescription + " " + styles.gradientText}>
					<p>Create, customize and share your own unique stories.</p>
				</div>

				<div className={styles.block}>
					<LinkButton to="/accounts/register" customStyle={styles.ctaButton}>Try it now for free</LinkButton>
				</div>
			</section>
		</main>
	);
}
