import {useNavigate} from "react-router-dom";
import React from "react";
import styles from "./style.module.css";
import {Icon, IconProps} from "@component/ui/Icon/Icon";
import {IconButton, IconButtonProps} from "@component/ui/Button";

export interface BreadcrumbButtonProps extends Omit<IconButtonProps, 'symbolId' | 'label'> {
	to?: string;
	iconOnly?: {
		label: string
	};
	iconProps?: IconProps;
	iconPlacement?: "start" | "end";
}

export const BreadcrumbButton = React.forwardRef(
	({
		to,
		iconProps,
		iconOnly,
		iconPlacement = "start",
		...props
	}: BreadcrumbButtonProps, forwardedRef: React.ForwardedRef<HTMLButtonElement>) => {
		const navigate = useNavigate();

		const clickHandler = to ? () => navigate(to) : undefined;

		if (iconOnly && iconProps) {
			return (
				<IconButton
					{...props}
					{...iconProps}
					label={iconOnly.label}
					ref={forwardedRef}
					className={styles.iconOnly}
					onClick={clickHandler}
				/>
			);
		}

		const isIconPlacedOnStart = iconPlacement === "start";
		const hasIcon = iconProps !== undefined;

		return (
			<button
				ref={forwardedRef}
				className={`
					${styles.button} 
					${hasIcon ? styles.buttonWithIcon : ""} 
					${hasIcon && !isIconPlacedOnStart ? styles.iconPlacedOnRight : ""}
				`}
				onClick={clickHandler}
				{...props}
			>
				{hasIcon && isIconPlacedOnStart && <Icon {...iconProps}/>}
				<span className={styles.buttonText}>{props.value}</span>
				{hasIcon && !isIconPlacedOnStart && <Icon {...iconProps}/>}
			</button>
		);
	});
