import {useCallback, useLayoutEffect, useState} from "react";
import {PRODUCTS} from "../../config.ts";
import {useAuthenticatedContext, useRefreshUserData} from "@app/stores/auth";
import Analytics, {APP_EVENTS} from "@app/analytics.ts";
import {showToast} from "@app/providers/toast";
import {useUpdateAccessInfo} from "@app/providers/access-info";
import {captureException} from "@toolbox/logger.ts";

interface LemonSqueezyAPI {
	Setup: (options: Record<string, unknown>) => void;
	Url: {
		Open: (url: string) => void;
		Close: () => void;
	}
}

function LemonSqueezy(): LemonSqueezyAPI {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window.createLemonSqueezy();

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return window.LemonSqueezy;
}

export enum ProductId {
	FIRST = 0,
	SECOND = 1,
	THIRD = 2,
	FOURTH = 3
}

function getCheckoutLink(productId: ProductId, userPid: string) {
	return `https://store.storyscapeai.app/checkout/buy/${PRODUCTS[productId]}?embed=0&dark=1&checkout[custom][user_pid]=${userPid}`;
}

interface EventCheckoutSuccessData {
	data?: {
		order?: {
			data?: {
				attributes: Partial<{
					identifier: string;
					total_usd: number;
					first_order_item: {
						id?: string;
						product_name?: string;
					};
					currency: string;
				}>;
			};
		};
	};
}

interface GA4PurchaseEventItem {
	item_id?: string;
	item_name?: string;
}

interface GA4PurchaseEvent {
	transaction_id?: string;
	currency?: string;
	value?: number;
	items: GA4PurchaseEventItem[];
}

function extractTransactionInfo(jsonData: EventCheckoutSuccessData): GA4PurchaseEvent {
	const attributes = jsonData?.data?.order?.data?.attributes;

	if (!attributes) {
		throw new Error("Error parsing checkout event: No attributes");
	}

	return {
		transaction_id: attributes.identifier,
		currency: attributes.currency,
		value: (attributes.total_usd || 100) / 100,
		items: [{
			item_id: attributes.first_order_item?.id,
			item_name: attributes.first_order_item?.product_name
		}]
	};
}

export function useProductCheckout(onSuccess?: () => void) {
	const {authUser} = useAuthenticatedContext();
	const updateAccessInfo = useUpdateAccessInfo();
	const dispatchRefreshToken = useRefreshUserData();
	const [isLoading, setIsLoading] = useState(false);

	if (authUser.isEmpty()) {
		throw new Error("Not logged in");
	}

	const userPid = authUser.get().pid;

	useLayoutEffect(() => {
		LemonSqueezy().Setup({
			eventHandler: (event: { event: string }) => {
				if (event.event === "Checkout.Success") {
					setIsLoading(true);

					try {
						const purchaseEvent = extractTransactionInfo(event as unknown as EventCheckoutSuccessData);

						Analytics.logEvent(APP_EVENTS.PURCHASE, purchaseEvent as unknown as Record<string, unknown>);
					} catch (e) {
						captureException(e);
					}

					// the timeout is so that we give the server to persist the new access info
					setTimeout(() => {
						updateAccessInfo()
							.then(res => {
								dispatchRefreshToken();

								showToast({
									title: '🎉 Thank you for your purchase!',
									description: `You have ${res.remaining} coins.`
								});
							})
							.catch(error => {
								showToast({
									title: 'ERROR_CHECKING_ACCESS',
									description: error.message
								});
							}).finally(() => {
							LemonSqueezy().Url.Close();

							setIsLoading(false);
							onSuccess?.();
						});
					}, 2500);
				}
			}
		});
	}, [updateAccessInfo, onSuccess, dispatchRefreshToken]);

	const openProductCheckout = useCallback((productId: ProductId) => {
		const url = getCheckoutLink(productId, userPid);

		setIsLoading(true);
		document.body.style.pointerEvents = "auto";
		LemonSqueezy().Url.Open(url);

		Analytics.logEvent(APP_EVENTS.BEGIN_CHECKOUT, {
			product_id: productId
		});

		setIsLoading(false);
	}, [userPid]);

	return {
		openProductCheckout,
		isLoading,
	}
}
