import React from 'react';

import {useLoaderData, useNavigate} from "react-router-dom";
import {ReadingSettingsProvider} from "@app/providers/reading-settings/provider.tsx";
import {SessionContextProvider} from "@app/providers/session/provider.tsx";
import {Chapters, StoryContainer, StoryTitle} from "../session/components/SessionComponent"
import {EmptyContent} from "@component/EmptyContent";
import ErrorImg from "@images/png/512/🫤.png";
import NotFoundImg from "@images/png/512/🙀.png";
import {useReadingSettings} from "@app/providers/reading-settings";
import {useSessionContext} from "@app/providers/session/hooks.ts";
import LoadingImg from "@images/png/512/🦜.png";
import {BackgroundImage} from "../session/components/BlurredBackground";
import {SettingsButton} from "../session/components/SettingsButton";
import {NewStoryButtonLink} from "@component/NewStoryButtonLink";

import styles from "./styles.module.css";

export function Story(): React.JSX.Element {
	const response = useLoaderData() as string | { message: string };
	const navigate = useNavigate();

	if (typeof response === "object" && "message" in response) {
		return (
			<EmptyContent
				title={"An error occurred"}
				description={response.message}
				imgSrc={ErrorImg}
			>
				<button
					className={"secondaryButton"}
					onClick={() => navigate(-1)}
				>
					Go back
				</button>

				<button onClick={() => navigate(0)}>Try again</button>
			</EmptyContent>
		);
	}

	return (
		<ReadingSettingsProvider>
			<SessionContextProvider sessionId={response as string}>
				<StoryComponent/>
			</SessionContextProvider>
		</ReadingSettingsProvider>
	);
}

export function StoryComponent() {
	const [readingSettings] = useReadingSettings();

	const {state: session} = useSessionContext();

	const navigate = useNavigate();

	if (session.error != null) {
		return (
			<EmptyContent
				title={session.error.title}
				description={session.error.description}
				imgSrc={NotFoundImg}
			>
				<button
					className={"secondaryButton"}
					onClick={() => navigate(-1)}
				>
					Go back
				</button>
			</EmptyContent>
		);
	}

	if (!session.isHydrated) {
		return <EmptyContent title={"Loading..."} description={""} imgSrc={LoadingImg}/>;
	}

	const {
		title,
		chapters,
	} = session;

	return (
		<StoryContainer>
			<BackgroundImage
				chapters={chapters}
				animateBackground={readingSettings.animateBackground}
			/>

			<StoryTitle title={title}/>

			<Chapters
				chapters={chapters}
				fontSizeEm={readingSettings.fontSizeEm}
				readOnly={true}
			>
				<div className={styles.exitButtons}>
					<button
						className={"secondaryButton"}
						onClick={() => navigate(-1)}
					>
						Go back
					</button>

					<NewStoryButtonLink/>
				</div>
			</Chapters>

			<SettingsButton/>
		</StoryContainer>
	);
}
