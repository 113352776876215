import React, {useRef} from "react";
import {motion, useScroll, useSpring, useTransform} from "framer-motion";
import styles from "./styles.module.css";

export function PreviewBlock(props: {
	emoji: string,
	title: string,
	description: string,
	image: string,
	imagePosition: "left" | "right"
}) {
	const {emoji, title, description, image, imagePosition} = props;

	const showImageOnTheLeft = imagePosition === "left";

	const ref = useRef(null);
	const {scrollYProgress} = useScroll({
		target: ref,
		offset: ["start end", "start center"]
	});

	const spring = useSpring(scrollYProgress, {
		stiffness: 600,
		damping: 200,
	});

	const opacity = useTransform(spring, [0, 1], [0, 1]);
	const scale = useTransform(spring, [0, 1], [0.2, 1]);
	const rotate = useTransform(spring, [0, 1], [!showImageOnTheLeft ? -360 : 360, 0]);
	const imgRotate = useTransform(spring, [0, 1], [!showImageOnTheLeft ? 8 : -8, 0]);

	return (
		<>
			{showImageOnTheLeft &&
				<motion.img style={{rotate: imgRotate}} ref={ref} alt={""} src={image} className={styles.previewImage}/>}

			<div>
				<motion.img
					style={{
						opacity,
						scale,
						rotate
					}}
					src={emoji}
					alt={""}
					className={styles.previewEmoji}
				/>

				<strong><p>{title}</p></strong>
				<p>{description}</p>
			</div>

			{!showImageOnTheLeft &&
				<motion.img style={{rotate: imgRotate}} ref={ref} alt={""} src={image} className={styles.previewImage}/>}
		</>
	);
}
