import {ActionFunctionArgs} from "@remix-run/router/utils";
import {newStory} from "@app/apis/sessions.ts";
import {redirect} from "react-router-dom";
import {createChapterDTO, SessionState} from "@app/providers/session/dtos.ts";
import {log} from "@toolbox/logger.ts";
import Analytics, {APP_EVENTS} from "@app/analytics.ts";
import {addSession, hasSession} from "@app/providers/session/db.ts";

export async function action({request}: ActionFunctionArgs) {
	const formData = await request.formData();
	const {pov, theme, nsfwFilter, imageTags} = Object.fromEntries(formData) as {
		pov: string;
		theme: string;
		nsfwFilter: string;
		imageTags: string;
	};

	if (pov === undefined || pov === "-1" || isNaN(Number(pov))) {
		return {
			error: 'Invalid POV value.'
		};
	}

	if (theme === undefined || theme.length > 512 || theme.length === 0) {
		return {
			error: 'Invalid story theme. 512 characters max.'
		};
	}

	const tags = imageTags.trim() === "" ? undefined : imageTags.split(",").map(tag => tag.trim());

	try {
		const reqBody = {
			story_pov: 1 + Number(pov),
			story_theme: theme as string,
			safe_filter: Boolean(nsfwFilter),
			...(tags ? {image_tags: tags.join(", ")} : {})
		};

		Analytics.logEvent(APP_EVENTS.NEW_STORY, {
			'story_pov': reqBody.story_pov,
			'safe_filter': reqBody.safe_filter,
		});

		const res = await newStory(reqBody);

		if (res.error && res.error !== '') {
			const error = res.error;

			log(error);

			if (error === 'nsfw') {
				return {
					error: "We can't generate that story. Try with a different one!"
				};
			} else {
				return {
					error: "Something went wrong."
				};
			}
		}

		const remaining_coins = res.first_chapter.remaining_uses;
		// todo: here, we'll update access-info store, when ready.

		const sessionAlreadyExists = await hasSession(res.session_id);

		if (!sessionAlreadyExists) {
			await addSession({
				id: res.session_id,
				title: res.story_title,
				chapters: [createChapterDTO(res.first_chapter)],
				isLastChapter: false,
				isLoading: false,
				isFinished: false,
				isHydrated: false,
			} as SessionState);
		}

		return redirect("/session/" + res.session_id);
	} catch (e: unknown) {
		return {
			error: e instanceof Error ? e.message : `An error occurred: ${e}`
		};
	}
}
