import {useCallback, useContext, useMemo} from "react";
import {SessionContext} from "@app/providers/session/provider.tsx";
import {fetchNextChapter, regenChapterImage, RegenChapterImageOptions} from "@app/providers/session/dispatchers.ts";
import {useAccessInfoContext} from "@app/providers/access-info";

export function useSessionContext() {
	const sessionContext = useContext(SessionContext);

	if (!sessionContext) {
		throw new Error('useSessionContext must be used within an SessionContextProvider');
	}

	return sessionContext;
}

export function useSessionActions() {
	const {dispatch: accessInfoDispatch} = useAccessInfoContext();
	const {dispatch: sessionDispatch} = useSessionContext();

	const fetchNextChapterAction = useCallback(async (
		session_id: string,
		player_choice?: number,
		player_idea?: string
	) => {
		return fetchNextChapter(
			sessionDispatch,
			accessInfoDispatch,
			session_id,
			player_choice,
			player_idea
		);
	}, [sessionDispatch, accessInfoDispatch]);

	return useMemo(() => {
		return {
			fetchNextChapterAction,
		}
	}, [fetchNextChapterAction]);
}

export function useSessionChapterActions(chapterIndex: number) {
	const {dispatch: accessInfoDispatch} = useAccessInfoContext();
	const {dispatch: sessionDispatch, state: {
		id,
	}} = useSessionContext();

	const regenImage = useCallback(async (image_tags: RegenChapterImageOptions["image_tags"]) => {
		return regenChapterImage(sessionDispatch, accessInfoDispatch, {
			session_id: id,
			chapter_index: chapterIndex,
			image_tags,
		});
	}, [sessionDispatch, accessInfoDispatch, id, chapterIndex]);

	const selectImage = useCallback((index: number) => {
		sessionDispatch({
			type: 'SET_SELECTED_IMAGE_INDEX',
			payload: {
				selectedImageIndex: index,
				chapterIndex: chapterIndex,
			},
		});
	}, [chapterIndex, sessionDispatch]);

	return useMemo(() => {
		return {
			regenImage,
			selectImage
		}
	}, [regenImage, selectImage]);
}
