import {ChapterImage, NewSessionResponse, NextChapterResponse} from "@app/apis/sessions.models.ts";
import {ChapterDTO, createChapterDTO, SessionState} from "@app/providers/session/dtos.ts";
import {updateSession} from "@app/providers/session/db.ts";
import {ToastPayload} from "@app/providers/toast";

type SessionHydratedAction = {
	type: 'SESSION_HYDRATED';
	payload: SessionState;
};

type CriticalErrorAction = {
	type: 'CRITICAL_ERROR';
	payload: ToastPayload;
}

type SetSelectedImageIndexAction = {
	type: 'SET_SELECTED_IMAGE_INDEX';
	payload: {
		selectedImageIndex: number,
		chapterIndex: number,
	};
};

type RegenImageAction = {
	type: 'REGEN_IMAGE_RECEIVED';
	payload: {
		image: ChapterImage,
		chapterIndex: number,
	};
};

type NewSessionLoadedAction = {
	type: 'NEW_SESSION_LOADED';
	payload: NewSessionResponse;
};

type NextChapterLoadedAction = {
	type: 'NEXT_CHAPTER_LOADED';
	payload: {
		chapterResponse: NextChapterResponse;
		player_choice?: number;
		player_idea?: string;
	};
};

export type SessionAction =
	| SessionHydratedAction
	| CriticalErrorAction
	| NewSessionLoadedAction
	| NextChapterLoadedAction
	| RegenImageAction
	| SetSelectedImageIndexAction
	| {
	type:
		| 'END_SESSION'
		| 'REQUEST_NEXT_CHAPTER'
		| 'ERROR_GETTING_NEXT_CHAPTER'
		| 'ERROR_GETTING_NEW_SESSION'
		| 'REGEN_IMAGE'
		| 'ERROR_REGEN_IMAGE';
	payload?: unknown;
};

export type SessionContextState = SessionState;

export function reducer(state: SessionContextState, action: SessionAction): SessionContextState {
	switch (action.type) {
		case 'CRITICAL_ERROR': {
			return {
				...state,
				error: action.payload,
			}
		}

		case 'SESSION_HYDRATED' : {
			return {
				...action.payload,
				isHydrated: true,
			};
		}

		case 'END_SESSION': {
			if (state.id === '') {
				return state;
			}

			return {
				...state,
				isFinished: true,
			};
		}

		case 'REQUEST_NEXT_CHAPTER': {
			return {
				...state,
				isLoading: true,
			};
		}

		case 'ERROR_GETTING_NEXT_CHAPTER': {
			return {
				...state,
				isLoading: false
			};
		}

		case 'NEXT_CHAPTER_LOADED': {
			const {
				chapterResponse,
				player_choice: player_last_choice,
				player_idea: player_last_idea
			} = action.payload;

			const chapterDTO = createChapterDTO(chapterResponse);

			const newSession = {
				...state,
				isLoading: false,
				chapters: [
					...state.chapters.map((chapter, index) => {
						if (index === state.chapters.length - 1) {
							return {
								...chapter,
								playerChoice: player_last_choice ? chapter.choices?.[player_last_choice]?.choice_text : player_last_idea,
							};
						}

						return chapter;
					}),
					chapterDTO,
				],
				isLastChapter: !chapterResponse.choices || chapterResponse.choices.length === 0,
			};

			updateSession(state.id, newSession);

			return {
				...state,
				...newSession
			};
		}

		case 'REGEN_IMAGE': {
			return {
				...state,
				isLoading: true,
			};
		}

		case 'SET_SELECTED_IMAGE_INDEX': {
			const newChapters = state.chapters.map((chapter, index) => {
				if (index === action.payload.chapterIndex) {
					return {
						...chapter,
						selectedImageIndex: action.payload.selectedImageIndex,
					};
				}

				return chapter;
			})

			updateSession(state.id, {chapters: newChapters});

			return {
				...state,
				chapters: newChapters
			};
		}

		case 'ERROR_REGEN_IMAGE': {
			return {
				...state,
				isLoading: false,
			};
		}

		case 'REGEN_IMAGE_RECEIVED': {
			const {image, chapterIndex} = action.payload;

			const newChapters: ChapterDTO[] = state.chapters.map<ChapterDTO>((chapter, index): ChapterDTO => {
				if (index === chapterIndex) {
					// we know chapter.images is defined, since we wouldn't be here if it wasn't
					const images = [...chapter.images!, image];

					return {
						...chapter,
						images,
						selectedImageIndex: images.length - 1
					};
				} else {
					return chapter;
				}
			});

			updateSession(state.id, {chapters: newChapters});

			return {
				...state,
				chapters: newChapters,
				isLoading: false,
			};
		}

		default:
			return state;
	}
}
