import styles from "./styles.module.css";

interface IconWithStatusProps extends IconProps {
	variant: "positive" | "negative" | "notice";
}

export function IconWithStatus(
	{
		variant,
		...props
	}: IconWithStatusProps) {
	return (
		<div style={{
			position: "relative",
		}}>
			<div style={{
				position: "absolute",
				width: "7px",
				height: "7px",
				bottom: "0px",
				borderRadius: "50%",
				backgroundColor: `var(--pw-${variant}-color)`,
				right: "0px",
			}}/>
			<Icon {...props}/>
		</div>
	);
}

export interface IconProps {
	symbolId?: string;
	imgSrc?: string;
	fontSizeRem?: number;
	className?: string;
	fill?: boolean;
	offset?: {
		x: number;
		y: number;
	};
}

export function Icon(props: IconProps) {
	const {symbolId, imgSrc, fontSizeRem, fill = false, className, offset} = props;

	const cssTranslate = offset ? `translate(${offset.x}px, ${offset.y}px)` : "";

	if (imgSrc) {
		const imgSize = fontSizeRem ? {
			height: `${fontSizeRem}rem`,
			width: "auto"
		} : {};

		return <img
			className={`${styles.img} ${className || ""}`}
			src={imgSrc}
			alt=""
			style={{
				transform: `${cssTranslate}`,
				...imgSize
			}}
		/>;
	}

	return <span
		className={`${styles.icon} ${fill ? styles.fill : ""} ${className || ""}`}
		style={{
			transform: `${cssTranslate}`,
			fontSize: fontSizeRem ? `${fontSizeRem}rem` : undefined,
		}}
	>
		{symbolId}
	</span>;
}
