import {SERVICE_API_URL} from '../../config.ts';

export enum IdentityProvider {
  Google = 1,
  Microsoft = 2,
}

export interface Tokens {
  accessToken: string;
  tokenType: string;
  expiresAt: number;
  refreshToken: string;
}

export interface UserDto {
  pid: string;
  v2_access: boolean;
  isPro: boolean;
  username: string;
  email: string;
  avatarUrl: string;
  idp: IdentityProvider;
}

export interface Credentials extends Tokens {
  user: UserDto;
}

interface FetchConfig {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: {[key: string]: string};
  body?: BodyInit | null;
}

export function request(
  endpoint: string,
  bearer_token: string | null,
  reqConfig: FetchConfig = {},
) {
  const headers: {[key: string]: string} = {};

  if (bearer_token) {
    headers.Authorization = `Bearer ${bearer_token}`;
  }

  const config: FetchConfig = {
    ...reqConfig,
    headers: {
      ...headers,
      ...reqConfig.headers,
    },
  };

  return fetch(`${SERVICE_API_URL}/accounts/${endpoint}`, config).then(
    async response => {
      if (response.status === 401) {
        throw new Error("Wrong email or password!");
      }

      if (response.status === 403) {
        throw new Error('Unauthorized!');
      }

      if (response.ok) {
        try {
          return await response.json();
        } catch (e) {
          return {};
        }
      } else {
        let json;

        try {
          json = await response.json();
        } catch (e) {
          // no json response
        }

        if (json) throw new Error(json.errors?.join(', ') || 'Unknown error');

        const errorMessage = await response.text();

        throw new Error(errorMessage);
      }
    },
  );
}

function objectToParams(obj: object) {
  return Object.entries(obj)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export function authWithOAuth(idToken: string, idp: IdentityProvider): Promise<Credentials> {
  return request('oauth', null, {
    body: objectToParams({idToken, idp}),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  });
}

export function deleteAccount(
  idToken: string,
  bearerToken: string
) {
  return request('delete', bearerToken, {
    body: objectToParams({
      idToken
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'DELETE',
  });
}

export function refreshToken(
  refreshToken: string,
  bearer_token: string,
): Promise<Tokens> {
  return request('refresh', bearer_token, {
    body: JSON.stringify({refreshToken}),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
}

export function getUser(
  bearer_token: string,
): Promise<UserDto> {
  return request('user', bearer_token, {
    method: 'POST',
  });
}
