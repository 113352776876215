import React, {useMemo, useState} from "react";
import styles from "./styles.module.css";
import {ChapterDTO} from "@app/providers/session/dtos.ts";
import {IconButton} from "@component/ui/Button";
import RegenIcon from "@images/emojis/sparkles_3d.png";
import {PopOver} from "@component/ui/PopOver";
import ThinkingFace from "@images/png/512/🤔.png";
import {useSessionChapterActions, useSessionContext} from "@app/providers/session/hooks.ts";
import {ChapterImage} from "@app/apis/sessions.models.ts";
import {addPeriodIfMissing, capitalizeText} from "@toolbox/utils.ts";

interface ChapterProps {
	chapter: ChapterDTO;
	chapterIndex: number;
	readOnly: boolean;
}

export function ChapterComponent({chapter, chapterIndex, readOnly}: ChapterProps) {
	const {textParagraphs} = chapter;

	const hasImage = chapter.images && chapter.images.length > 0;

	const paragraphs = useMemo(() => {
		let totalDelay = 0;

		function Words({words}: { words: string[]}) {
			return words.map((word, j) => {

				const Word = (
					<span
						className={styles.word}
						key={j}
						style={{
							//eslint-disable-next-line @typescript-eslint/ban-ts-comment
							//@ts-ignore
							"--word-i": totalDelay + "s"
						}}
					>
							{word}{" "}
						</span>
				);

				totalDelay += 0.02;

				return Word;
			});
		}

		return textParagraphs.map((paragraphText, i) => {
			// Drop cap
			if (i === 0) {
				const firstLetter = paragraphText.charAt(0);
				const rest = paragraphText.slice(1);

				const words = readOnly ? [] : rest.split(" ");

				if (chapterIndex === 0) {
					return <span key={i}>
						{hasImage && !readOnly && (
							<ImageWithControls
								chapterIndex={chapterIndex}
								readOnly={readOnly}
							/>
						)}

						{hasImage && readOnly && (
							<Image
								chapterIndex={chapterIndex}
								image={chapter.images![chapter.selectedImageIndex]}
								readOnly={readOnly}
							/>
						)}

						<p>
							<span className={styles.dropCap}>{firstLetter}</span>
							{readOnly ? paragraphText : <Words words={words}/>}
						</p>
					</span>;
				} else {
					return <p key={i}>
						<span className={styles.dropCap}>{firstLetter}</span>
						{readOnly ? paragraphText : <Words words={words}/>}
					</p>;
				}
			}

			const words = readOnly ? [] : paragraphText.split(" ");

			if (chapterIndex > 0 && i === chapter.imagePositionBetweenParagraphs) {
				return <span key={i}>
					{hasImage && !readOnly && (
						<ImageWithControls
							chapterIndex={chapterIndex}
							readOnly={readOnly}
						/>
					)}

					{hasImage && readOnly && (
						<Image
							chapterIndex={chapterIndex}
							image={chapter.images![chapter.selectedImageIndex]}
							readOnly={readOnly}
						/>
					)}

					<p>
						{readOnly ? paragraphText : <Words words={words}/>}
					</p>
				</span>;
			}

			return <p key={i}>
				{readOnly ? paragraphText : <Words words={words}/>}
			</p>;
		});
	}, [textParagraphs, chapterIndex, chapter, hasImage, readOnly]);

	return (
		<div className={styles.chapterCont}>
			{paragraphs}

			{!!chapter.playerChoice && (
				<p className={styles.playerChoice}>
					{addPeriodIfMissing(capitalizeText(chapter.playerChoice))}
				</p>
			)}
		</div>
	);
}

export const Chapter = React.memo(ChapterComponent, (prevProps, nextProps) => {
	return (
		prevProps.chapterIndex === nextProps.chapterIndex &&
		prevProps.readOnly === nextProps.readOnly &&
		prevProps.chapter.text === nextProps.chapter.text &&
		prevProps.chapter.choices == nextProps.chapter.choices
	);
});

interface ImageWithControlsProps {
	chapterIndex: number;
	readOnly: boolean;
}

function ImageWithControls({
	chapterIndex,
	readOnly,
}: ImageWithControlsProps) {
	const {
		state: {
			isLoading: isContextLoading,
			chapters
		}
	} = useSessionContext();

	const chapter = chapters[chapterIndex];

	const {
		regenImage,
		selectImage
	} = useSessionChapterActions(chapterIndex);

	const [newTags, setNewTags] = useState(chapter.images?.[0].tags || "");
	const [isLoading, setIsLoading] = useState(false);

	const selectedImageIndex = chapter.selectedImageIndex;

	const images = chapter.images!;
	const image = images[selectedImageIndex];

	const hasMultipleImages = images.length > 1;

	const prevButtonDisabled = isLoading || isContextLoading || selectedImageIndex === 0;
	const nextButtonDisabled = isLoading || isContextLoading || selectedImageIndex === images.length - 1;

	const showGenerateNewImageButton = images.length <= 32;

	const onPrevButtonClick = () => {
		const newSelectedIndex = selectedImageIndex - 1;

		selectImage(newSelectedIndex);
		setNewTags(images[newSelectedIndex].tags || "");
	};

	const onNextButtonClick = () => {
		const newSelectedIndex = selectedImageIndex + 1;

		selectImage(newSelectedIndex);
		setNewTags(images[newSelectedIndex].tags || "");
	};

	const onGenerateANewImageClick = () => {
		setIsLoading(true);

		regenImage(newTags).finally(() => {
			setIsLoading(false);
		});
	};

	return (
		<Image
			chapterIndex={chapterIndex}
			image={image}
			readOnly={readOnly}
		>
			{hasMultipleImages && (
				<div className={styles.imageControls}>
					<IconButton
						label="Select previous image"
						iconProps={{
							symbolId: "navigate_before",
						}}
						fontSizeRem={1.5}
						disabled={prevButtonDisabled}
						onClick={onPrevButtonClick}
					/>
					<IconButton
						label="Select next image"
						iconProps={{
							symbolId: "navigate_next",
						}}
						fontSizeRem={1.5}
						disabled={nextButtonDisabled}
						onClick={onNextButtonClick}
					/>
				</div>
			)}

			{showGenerateNewImageButton && (
				<div className={styles.regenerateImage}>
					<PopOver
						width={"350px"}
						triggerElement={
							<IconButton
								label={isLoading ? "Generating new image..." : "Generate new image"}
								iconProps={{
									imgSrc: RegenIcon,
									className: isLoading ? styles.regenerateImageIcon : undefined,
								}}
								fontSizeRem={1.5}
								disabled={isLoading || isContextLoading}
							/>
						}
					>
						<div className={styles.regenerateImagePopup}>
							<p>
								<img src={ThinkingFace} alt={""}/>
								<strong>Is the image not good enough?</strong>
							</p>

							<p>You can generate a new one!</p>

							<p>
								The system will use a different prompt to make a new image.
							</p>

							<p>You can also change the image tags below.</p>

							<input
								type={"text"}
								id="imageTags"
								name="imageTags"
								value={newTags}
								disabled={isLoading || isContextLoading}
								onChange={(e) => setNewTags(e.target.value)}
								placeholder={"Image tags..."}
							/>

							<p><strong>This action costs 1 coin.</strong></p>

							<button
								disabled={isLoading || isContextLoading}
								onClick={onGenerateANewImageClick}
							>
								✨ Re-generate image
							</button>
						</div>
					</PopOver>
				</div>
			)}
		</Image>
	);
}

export function Image(props: {
	chapterIndex: number;
	image: ChapterImage;
	children?: React.ReactNode;
	readOnly: boolean;
}) {
	const {
		chapterIndex,
		image,
		children,
		readOnly
	} = props;

	const animationClass = readOnly ? "" : styles.chapterImgBlurred;

	return (
		<figure
			className={styles.chapterFigure}
			data-is-odd={chapterIndex % 2 === 1}
		>
			<img
				className={styles.chapterImg + " " + animationClass}
				src={image.url}
				alt={image.caption || ""}
			/>

			<figcaption title={image.caption}>
				{image.caption}
			</figcaption>

			{children}
		</figure>
	);
}
