import {json, redirect} from "react-router-dom";
import {isAuthenticated} from "@app/stores/auth";
import {LoaderFunctionArgs} from "@remix-run/router/utils";
import {FormType} from "./index.ts";

export async function loader({params}: LoaderFunctionArgs) {
	if (await isAuthenticated()) {
		throw redirect('/');
	}

	const action = params.action || "login";

	return json(action == "login" ? FormType.Login : FormType.Register);
}
