import React, {useEffect, useLayoutEffect, useState} from 'react';

import styles from "./styles.module.css";
import {Form, useActionData, useNavigate, useNavigation} from "react-router-dom";
import {ButtonGroup} from "@component/ui/ButtonGroup";
import RobotLargeImg from "@images/png/512/transition/Cyclone.png";
import * as Switch from '@radix-ui/react-switch';
import {IconButton} from "@component/ui/Button";
import {NsfwDisclaimerDialog} from "./NsfwDisclaimerDialog.tsx";
import {PopOver} from "@component/ui/PopOver";
import ReactDOM from "react-dom";
import {showToast} from "@app/providers/toast";
import {SectionCont, SectionMainContent, SectionTitle} from "@component/Layout";
import {useRemainingCoins} from "@app/providers/access-info";
import {GetMoreCoinsButton} from "@component/CoinsButton";

export function CreateStory(): React.JSX.Element {
	const errorMessage = useActionData() as unknown as { error: string };

	const [storyTheme, setStoryTheme] = useState<string>("");
	const [narrativePerspective, setNarrativePerspective] = useState(2);
	const [nsfwFilter, setNsfwFilter] = useState(true);
	const [imageTags, setImageTags] = useState<string>("");

	const [isNsfwDisclaimerDialogOpen, setIsNsfwDisclaimerDialogOpen] = useState(false);

	const remainingCoins = useRemainingCoins();

	const navigation = useNavigation();
	const navigate = useNavigate();

	const nsfwSwitchRef = React.useRef(null);

	useEffect(() => {
		if (errorMessage) {
			showToast({
				title: "Error loading new story",
				description: errorMessage.error
			});
		}
	}, [errorMessage]);

	const handleStoryThemeChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setStoryTheme(event.target.value);
	};

	const isLoading = navigation.state === 'loading' || navigation.state === "submitting";

	const cancel = (e: React.MouseEvent) => {
		e.preventDefault();

		navigate(-1)
	};

	const clear = (e: React.MouseEvent) => {
		e.preventDefault();

		setStoryTheme("");
		setNsfwFilter(true);
		setNarrativePerspective(2);
	};

	const isSubmitDisabled = !storyTheme;

	const isClearButtonDisabled = !storyTheme && nsfwFilter && narrativePerspective === 2;

	return (
		<SectionCont>
			<SectionMainContent>
				<SectionTitle
					title={"What kind of story would you like to create?"}
					showGoBackButton={true}
				/>

				<Form method={"post"} action={"/new"} replace={false} className={styles.form}>
					<div className={styles.formField}>
						<label htmlFor={"theme"}>
							<PopOver
								triggerElement={<IconButton symbolId={"help"} label={"What is a story prompt?"}/>}
								width={"350px"}
							>
								<div>
									<p>{`A story prompt is a short piece of text that gives the AI information about the story you want it to generate.`}</p>
									<p>{`It can include information about the characters, the setting, the plot, or any other element of the story that you find interesting.`}</p>
									<p>{`You can also mention a specific author in the prompt to match their writing style.`}</p>
								</div>
							</PopOver>

							<span>Story Prompt</span>
							<span className={styles.requiredMark} title={"This field is required"}>*</span>
						</label>

						<textarea
							id={"theme"}
							name={"theme"}
							required={true}
							disabled={isLoading}
							maxLength={512}
							onChange={handleStoryThemeChange}
							value={storyTheme}
							className={styles.textArea}
						/>
					</div>

					<div className={styles.formField}>
						<label htmlFor={"pov"}>
							<PopOver
								triggerElement={<IconButton symbolId={"help"} label={"What is a narrative perspective?"}/>}
								width={"350px"}
							>
								<div>
									<p>Narrative perspective is the point of view from which a story is told. It can be first person
										("I"), second person ("you"), or third person ("he," "she," "they").</p>

									<ul>
										<li><strong>First person:</strong> I woke up in a dark room, with no idea how I got there.</li>
										<li><strong>Second person:</strong> You wake up in a dark room, with no idea how you got there.</li>
										<li><strong>Third person:</strong> John woke up in a dark room, with no idea how he got there.</li>
									</ul>
								</div>
							</PopOver>

							<span>Narrative perspective</span>
						</label>

						<ButtonGroup
							id="pov"
							name="pov"
							disabled={isLoading}
							active={narrativePerspective}
							buttons={["1st person (I...)", "2nd person (You...)", "3rd person (They...)"]}
							onSelected={setNarrativePerspective}
						/>
					</div>

					<div className={styles.formField}>
						<label htmlFor={"imageTags"}>
							<PopOver
								triggerElement={<IconButton symbolId={"help"} label={"What are image tags?"}/>}
								width={"350px"}
							>
								<div>
									<p>Each image tag is a comma-separated <strong>keyword</strong> that you want the AI
										to <em>consider</em> when generating the images for the story.</p>
									<p>It could be an image style, an specific place, or anything else you want.</p>
									<p>These are some examples of image tags:</p>
									<ul>
										<li><b>colorful</b></li>
										<li><b>oil-painting</b></li>
										<li><b>3D</b></li>
										<li><b>romantic</b></li>
									</ul>
									<p>Note that image tags are just <em>suggestions</em>. There is no guarantee that they will be used.
									</p>
								</div>
							</PopOver>

							<span>Image tags</span>
						</label>

						<input
							type={"text"}
							id="imageTags"
							name="imageTags"
							disabled={isLoading}
							value={imageTags}
							onChange={(e) => {
								setImageTags(e.target.value)
							}}
						/>
					</div>

					<div className={styles.nsfwSwitch}>
						<label htmlFor="nsfwFilter">Prevent sensitive content</label>

						<NsfwDisclaimerDialog
							isOpen={isNsfwDisclaimerDialogOpen}
							setIsDialogOpen={setIsNsfwDisclaimerDialogOpen}
							onClosedCallback={(accepted) => {
								setNsfwFilter(!accepted);
							}}
							triggerElement={(
								<Switch.Root
									className={"secondaryButton " + styles.SwitchRoot}
									id="nsfwFilter"
									name={"nsfwFilter"}
									ref={nsfwSwitchRef}
									disabled={isLoading}
									aria-label={"Prevent sensitive content"}
									checked={nsfwFilter}
									onCheckedChange={(isChecked) => {
										if (!isChecked) {
											setIsNsfwDisclaimerDialogOpen(true);
										} else {
											setNsfwFilter(true);
										}
									}}
								>
									<Switch.Thumb className={styles.SwitchThumb}/>
								</Switch.Root>
							)}
						/>
					</div>

					{errorMessage && <p className={styles.error}>{errorMessage.error}</p>}

					<div className={styles.formFooter}>
						<div>
							<button
								className={"secondaryButton"}
								onClick={clear}
								disabled={isClearButtonDisabled || isLoading}
							>
								Clear fields
							</button>
						</div>

						<div className={styles.formFooterEnd}>
							<button
								className={"secondaryButton"}
								onClick={cancel}
								disabled={isLoading}
							>
								Cancel
							</button>

							{remainingCoins > 0 && (
								<button
									type={"submit"}
									disabled={isSubmitDisabled || isLoading}
									title={isSubmitDisabled ? "Please fill out all required fields" : ""}
								>
									Continue
								</button>
							)}

							{remainingCoins <= 0 && (
								<GetMoreCoinsButton
									label={"Get more coins to continue"}
									remaining={remainingCoins}
									showLabel={false}
								/>
							)}
						</div>
					</div>
				</Form>
			</SectionMainContent>

			<LoadingOverlay isLoading={isLoading}/>
		</SectionCont>
	);
}

const LoadingOverlay = ({isLoading}: { isLoading: boolean }) => {
	useLayoutEffect(() => {
		const body = document.body;

		if (isLoading) {
			body.classList.add("overflow-hidden");

			window.scrollTo({top: 0, left: 0, behavior: "smooth"});
		} else {
			body.classList.remove("overflow-hidden");
		}

		return () => {
			body.classList.remove("overflow-hidden");
		}
	}, [isLoading]);

	return ReactDOM.createPortal((
			<div className={styles.loadingCont + ` ${isLoading ? styles.loadingVisible : ""}`}>
				{isLoading && <img src={RobotLargeImg} alt={""}/>}
				{isLoading && "Loading..."}
			</div>
		), document.getElementById("portal-root")!
	);
};
