import styles from './style.module.css';
import React from "react";
import {LightModeButton} from "./LightModeButton.tsx";
import {AuthCTAButtons} from "./AuthCTAButtons.tsx";
import {AvatarDropdown} from "./AvatarDropdown.tsx";
import {AppName} from "./AppName.tsx";
import {useAuthenticatedContext} from "@app/stores/auth";
import {TopBarCoinsButton} from "@component/CoinsButton";

import {useRemainingCoins} from "@app/providers/access-info";

interface TopBarProps {
	largeTopBar?: boolean;
	children?: React.ReactNode;
	hasV2Access: boolean;
}

export function TopBar(props: TopBarProps): React.JSX.Element {
	const {isAuthenticated, authUser} = useAuthenticatedContext();
	const remainingCoins = useRemainingCoins();

	if (!isAuthenticated) {
		return (
			<header className={styles.header + " " + styles.largeHeader}>
				<div className={styles.centered}>
					<AppName isProUser={false}/>

					<div className={styles.right}>
						<LightModeButton visible={false}/>
						<AuthCTAButtons/>
					</div>
				</div>
			</header>
		);
	}

	const user = authUser.get();

	return (
		<header className={styles.header + " " + (props.largeTopBar ? styles.largeHeader : "")}>
			<AppName isProUser={user.isPro}/>

			{isAuthenticated && (
				<div className={styles.topbarCenter}>
					{props.hasV2Access && <TopBarCoinsButton remaining={remainingCoins} isPro={user.isPro}/>}
				</div>
			)}

			{isAuthenticated && user && (
				<>
					<LightModeButton visible={props.hasV2Access}/>

					<AvatarDropdown
						avatarUrl={user.avatarUrl}
						username={user.username}
						email={user.email}
					/>
				</>
			)}

			{!isAuthenticated && (
				<>
					<LightModeButton/>
					<AuthCTAButtons/>
				</>
			)}
		</header>
	);
}
