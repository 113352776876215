import styles from './style.module.css';
import React, {useRef} from "react";
import {Icon, IconProps} from "@component/ui/Icon/Icon.tsx";

export interface CheckboxButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	iconProps?: IconProps;
	selected: boolean;
}

export const CheckboxButton = React.forwardRef(
	(props: CheckboxButtonProps, forwardedRef: React.ForwardedRef<HTMLButtonElement>) => {
		const {
			iconProps,
			className = "",
			children,
			selected,
			disabled,
			...rest
		} = props;

		return (
			<button {...rest} disabled={disabled} ref={forwardedRef} className={
				[
					styles.textIconButton,
					!iconProps ? styles.textIconButtonNoIcon : "",
					selected ? styles.textIconButtonActive : "",
					className
				].join(" ")
			}>
				{iconProps && <Icon
					{...iconProps}
				/>}

				<span className={styles.textIconButtonText}>
					{children}
				</span>
			</button>
		);
	});

export interface ButtonGroupProps {
	buttons: string[];
	onSelected?: (index: number) => void;
	name: string;
	id: string;
	active: number;
	disabled?: boolean;
}

export function ButtonGroup({buttons, onSelected, name, id, active, disabled}: ButtonGroupProps): React.JSX.Element {
	const firstButton = useRef<HTMLButtonElement>(null);

	const handleButtonClick = (index: number) => {
		onSelected?.(index);
	};

	const ariaDescription = active === -1 ? "Toggle group: none selected" : `Toggle group: selected is "${buttons[active]}"`;

	return (
		<div className={styles.buttonGroup} aria-description={ariaDescription}>
			<input
				readOnly
				aria-hidden={true}
				tabIndex={-1}
				value={active}
				name={name}
				id={id}
				disabled={disabled}
				className={styles.hiddenInput}
				onFocus={() => {
					if (firstButton.current) {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						firstButton.current.focus({focusVisible: true});
					}
				}}/>

			{buttons.map((label, index) => (
				<CheckboxButton
					key={index}
					disabled={disabled}
					ref={index === 0 ? firstButton : undefined}
					onClick={(e) => {
						e.preventDefault();

						handleButtonClick(index);
					}}
					selected={active === index}
				>
					{label}
				</CheckboxButton>
			))}
		</div>
	);
}
