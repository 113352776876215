import React from "react";
import {ReadingSettingsContext, ReadingSettingsState} from "./provider.tsx";

export const LOCAL_STORAGE_KEY = 'readingSettings';
export const defaultReadingSettings = {
	fontSizeEm: 1.9,
	animateBackground: true,
};

export function useReadingSettings(): [ReadingSettingsState, React.Dispatch<React.SetStateAction<ReadingSettingsState>>] {
	const context = React.useContext(ReadingSettingsContext);

	if (!context) {
		throw new Error('useReadingSettings must be used within a ReadingSettingsProvider');
	}

	return context;
}
