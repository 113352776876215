import React, {ReactNode} from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import style from "./style.module.css";
import {MenuButton} from "@component/ui/Button";
import {useNavigate} from "react-router-dom";
import {IconProps} from "@component/ui/Icon/Icon.tsx";
import styles from "@component/ui/PopOver/style.module.css";

interface DropDownMenuProps extends DropdownMenu.DropdownMenuContentProps, React.RefAttributes<HTMLDivElement> {
	loop?: boolean;
	sideOffset?: number;
	showArrow?: boolean;
	triggerElement: React.ReactNode;
}

export const DropDownMenu = React.forwardRef(
	(allProps: DropDownMenuProps, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
		const {
			loop = true,
			sideOffset = 1,
			triggerElement,
			showArrow = true,
			children,
			...props
		} = allProps;

		return (
			<DropdownMenu.Root>
				<DropdownMenu.Trigger asChild>
					{triggerElement}
				</DropdownMenu.Trigger>

				<DropdownMenu.Portal>
					<DropdownMenu.Content
						{...props}
						ref={forwardedRef}
						className={style.menu}
						loop={loop}
						sideOffset={sideOffset}
					>
						{children}

						{showArrow && <DropdownMenu.Arrow className={styles.arrow}/>}
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
		);
	}
);

interface MenuItemWrapperProps extends DropdownMenu.DropdownMenuItemProps, React.RefAttributes<HTMLDivElement> {
	asChild?: boolean;
}

export const MenuItemWrapper = React.forwardRef(
	({asChild = true, children, ...props}: MenuItemWrapperProps, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
		return (
			<DropdownMenu.Item {...props} ref={forwardedRef} asChild={asChild}>
				{children}
			</DropdownMenu.Item>
		);
	});

export interface MenuItemProps extends MenuItemWrapperProps {
	iconProps?: IconProps;
	value?: string;
	to?: string;
	replace?: boolean;
	children?: ReactNode;
}

export function MenuItem(props: MenuItemProps) {
	const {
		onSelect,
		to,
		replace,
		iconProps,
		disabled,
		value,
		children,
		...forwardedProps
	} = props;

	const navigate = useNavigate();

	const handleOnSelect = (e: Event) => {
		if (to) {
			navigate(to, {replace: replace})
		}

		onSelect?.(e);
	}
	return (
		<MenuItemWrapper asChild={true} onSelect={handleOnSelect} {...forwardedProps}>
			<MenuButton iconProps={iconProps} disabled={disabled}>
				{!children && value}
				{children}
			</MenuButton>
		</MenuItemWrapper>
	);
}
