import {v2UserRequiredLoader} from "@toolbox/utils.ts";
import {getAll} from "@app/providers/session/db.ts";

export async function loader() {
	await v2UserRequiredLoader();

	const allSessions = await getAll() || [];

	return allSessions.filter(session => session.isLastChapter);
}
