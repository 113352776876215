import {GoogleIcon} from "./GoogleIcon";
import {MicrosoftIcon} from "@component/OAuthSignInButton/MicrosoftIcon.tsx";
import {IdentityProvider} from "@app/apis/accounts.ts";

type SignInButtonProps = {
	isContextSignIn: boolean;
	onClick: () => void;
	center: boolean;
	isDisabled: boolean;
	idp: IdentityProvider;
}

export function SignInButton({onClick, isDisabled, center, isContextSignIn, idp}: SignInButtonProps) {
	const isGoogleProvider = idp === IdentityProvider.Google;
	const providerName = isGoogleProvider ? 'Google' : 'Microsoft';

	return (
		<button
			onClick={onClick}
			className={"secondaryButton"}
			disabled={isDisabled}
			style={{
				display: 'flex',
				alignItems: 'center',
				alignSelf: center ? 'center' : 'auto',
				maxHeight: "38px",
				minWidth: "250px",
			}}
		>
			{isGoogleProvider && <GoogleIcon style={{
				marginRight: 8,
				padding: 2,
			}}/>}

			{!isGoogleProvider && <MicrosoftIcon style={{
				marginRight: 8,
				padding: 2,
			}}/>}

			{isContextSignIn ? 'Sign in' : 'Sign up'} with {providerName}
		</button>
	);
}
