import {log} from "@toolbox/logger.ts";

export enum APP_EVENTS {
	SIGNUP = 'sign_up',
	LOGIN = 'login',
	NEW_STORY = 'new_story',
	NEXT_CHAPTER = 'next_chapter',
	REGEN_IMAGE = 'regen_image',
	VIEW_CART = 'view_cart',
	BEGIN_CHECKOUT = 'begin_checkout',
	PURCHASE = 'purchase',
	LOGOUT = 'logout',
	ACCOUNT_DELETE = 'account_delete',
}

export interface IAnalytics {
	logEvent(eventName: APP_EVENTS, params?: Record<string, unknown>): void
}

class Analytics implements IAnalytics {
	private readonly prod: boolean;

	constructor(useGtag: boolean) {
		this.prod = useGtag;
	}

	logEvent(eventName: string, params?: Record<string, unknown>): void {
		if (this.prod) {
			window.gtag('event', eventName, params);
		} else {
			log("Analytics.logEvent: ", eventName, params);
		}
	}
}

const instance: IAnalytics = new Analytics(window.gtag !== undefined);

export default instance;
