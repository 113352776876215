import React from "react";
import {UserAvatar} from "./UserAvatar.tsx";
import {UserIdCard} from "@component/UserIdCard/UserIdCard.tsx";
import {useLogout} from "@app/stores/auth";
import {DropDownMenu, MenuItem, MenuItemWrapper} from "@component/ui/DropDownMenu";

interface AvatarDropdownProps {
	avatarUrl: string;
	username: string;
	email: string;
}

export function AvatarDropdown(props: AvatarDropdownProps): React.JSX.Element {
	const {avatarUrl, username} = props;
	const {logOut} = useLogout();

	return (
		<DropDownMenu
			triggerElement={(
				<UserAvatar avatarUrl={avatarUrl} username={username}/>
			)}
			loop={true}
			sideOffset={5}
			align={"end"}
		>
			<MenuItemWrapper disabled={true}>
				<div style={{
					paddingRight: 0
				}}>
					<UserIdCard {...props}/>
				</div>
			</MenuItemWrapper>

			<MenuItem
				to="/account"
				iconProps={{
					symbolId: "manage_accounts"
				}}
				value={"Manage Account"}
				aria-label="Go to section to manage account"
			/>

			<MenuItem
				onSelect={logOut}
				iconProps={{
					symbolId: "logout"
				}}
				value={"Log out"}
				aria-label="log out"
			/>
		</DropDownMenu>
	);
}
