import React from "react";
import styles from "./styles.module.css";
import {PopOver} from "@component/ui/PopOver";
import {IconButton} from "@component/ui/Button";
import {useReadingSettings} from "@app/providers/reading-settings";

export function SettingsButton() {
	const [readingSettings, setReadingSettings] = useReadingSettings();

	const onFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setReadingSettings(state => ({
			...state,
			fontSizeEm: Number(e.target.value)
		}));
	};

	const onAnimateBackgroundChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setReadingSettings(state => ({
			...state,
			animateBackground: e.target.checked
		}));
	};

	return (
		<div className={styles.settingsButton}>
			<PopOver
				contentProps={{collisionPadding: 25}}
				triggerElement={(
					<IconButton symbolId={"settings"} label={"Styling settings"} fontSizeRem={1.5}/>
				)}
				width={"fit-content"}
			>
				<div className={styles.settingsButtonCont}>
					<label>
						<span>Font size</span>
						<input
							type="range"
							min={1}
							max={4}
							step={0.1}
							value={readingSettings.fontSizeEm}
							onChange={onFontSizeChange}
						/>
					</label>

					<label>
						<span>Animate background</span>
						<input type="checkbox" onChange={onAnimateBackgroundChange} checked={readingSettings.animateBackground}/>
					</label>
				</div>
			</PopOver>

		</div>
	);
}
