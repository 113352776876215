import {ChapterDTO} from "@app/providers/session/dtos.ts";
import styles from "./styles.module.css";

export function BackgroundImage(props: {
	chapters: ChapterDTO[];
	animateBackground: boolean;
}) {
	const {
		chapters,
		animateBackground
	} = props;

	const hasImages = (chapters[0].images?.length || 0) > 0;
	const previousChapterImage = chapters.at(-2)?.images?.[0].url || chapters[0].images?.[0].url;

	if (!hasImages || !previousChapterImage) {
		return null;
	}

	return (
		<BackgroundImageBase imageUrl={previousChapterImage} animateBackground={animateBackground}/>
	);
}


export function BackgroundImageBase(props: {
	imageUrl: string;
	animateBackground: boolean;
}) {
	const {
		imageUrl,
		animateBackground
	} = props;

	const backgroundClasses = styles.storyBackgroundImage + (
		animateBackground ? " " + styles.animatedStoryBackgroundImage : ""
	);

	return (
		<div className={styles.storyBackgroundImageCont}>
			<div className={backgroundClasses} style={{
				backgroundImage: `url(${imageUrl})`
			}}/>
		</div>
	);
}
