import {LoaderFunctionArgs} from "@remix-run/router/utils";
import {v2UserRequiredLoader} from "@toolbox/utils.ts";
import {redirect} from "react-router-dom";
import {hasSession} from "@app/providers/session/db.ts";

export async function loader({params}: LoaderFunctionArgs) {
	await v2UserRequiredLoader();

	const sessionId = params.sessionId;

	if (!sessionId) {
		return redirect(`/`);
	}

	const sessionDoesNotExist = !(await hasSession(sessionId));

	if (sessionDoesNotExist) {
		return redirect(`/`);
	}

	return sessionId;
}
