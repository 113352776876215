import React, {createContext, Dispatch, useEffect, useReducer,} from 'react';
import {useAuthenticatedContext} from "@app/stores/auth";
import {showToast} from "@app/providers/toast";
import {AccessInfoState, Action, reducer, updateAccessInfo} from "@app/providers/access-info/index.ts";

export type AccessInfoContextType = {
	state: AccessInfoState;
	dispatch: Dispatch<Action>;
};

export const AccessInfoContext = createContext<AccessInfoContextType | undefined>(undefined);

export function AccessInfoContextProvider({
	children,
}: {
	children: React.ReactNode;
}): React.JSX.Element {
	const {
		isAuthenticated
	} = useAuthenticatedContext();

	const [state, dispatch] = useReducer(reducer, {
		accessInfo: {
			remaining: 0,
			next_try_in_secs: 0,
		},
		isCheckingAccess: false,
	});

	useEffect(() => {
		if (!isAuthenticated) {
			return;
		}

		updateAccessInfo(dispatch).catch(error => {
			showToast({
				title: 'ERROR_CHECKING_ACCESS',
				description: error.message
			});
		});
	}, [isAuthenticated]);

	return (
		<AccessInfoContext.Provider value={{state, dispatch}}>
			{children}
		</AccessInfoContext.Provider>
	);
}
