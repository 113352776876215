import React from "react";
import * as ToastPrimitive from '@radix-ui/react-toast';

import style from "./style.module.css";
import {IconButton} from "@component/ui/Button";

interface ToastProps {
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	title?: string;
	description?: string;
}

export function Toast(props: ToastProps): React.JSX.Element {
	const {isOpen, onOpenChange, title, description} = props;

	return (
		<ToastPrimitive.Provider swipeDirection="right">
			<ToastPrimitive.Root open={isOpen} onOpenChange={onOpenChange} className={style.ToastRoot}>
				<ToastPrimitive.Title className={style.ToastTitle}>
					{title}
				</ToastPrimitive.Title>

				<ToastPrimitive.Description className={style.ToastDescription}>
					{description}
				</ToastPrimitive.Description>

				<ToastPrimitive.Close aria-label="Close" asChild>
					<IconButton symbolId="close" label="close"/>
				</ToastPrimitive.Close>
			</ToastPrimitive.Root>

			<ToastPrimitive.Viewport className={style.ToastViewport} />
		</ToastPrimitive.Provider>
	);
}
