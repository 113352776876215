import React, {useEffect, useRef} from "react";
import styles from "./style.module.css";

interface EmptyContentProps {
	title: string;
	description: string;
	symbolId?: string;
	imgSrc?: string;
	videoSrc?: string;
	children?: React.ReactNode;
}

export function EmptyContent(props: EmptyContentProps) {
	const {
		title,
		description,
		symbolId,
		imgSrc,
		videoSrc,
		children
	} = props;

	return (
		<div className={styles.noContent}>
			{!imgSrc && <div className={styles.noContentImage + " material-symbols-sharp"}>{symbolId}</div>}
			{!videoSrc && imgSrc && <img className={styles.img} src={imgSrc} alt=""/>}
			{videoSrc && <video className={styles.video} src={videoSrc} autoPlay loop/>}

			<h2>{title}</h2>
			<p>{description}</p>

			<div className={styles.noContentButtons}>
				{children}
			</div>
		</div>
	);
}

interface AnimatedEmptyContentProps {
	title: string;
	description: string;
	images: string[];
	children?: React.ReactNode;
}

export function AnimatedEmptyContent(props: AnimatedEmptyContentProps) {
	const {
		title,
		description,
		images,
		children
	} = props;

	const timeoutIdRef = useRef<number|null>(null);
	const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>(Math.floor(Math.random() * images.length));
	const [isFadeIn, setIsFadeIn] = React.useState<boolean>(true);

	useEffect(() => {
		const changeImage = () => {
			timeoutIdRef.current = setTimeout(() => {
				setSelectedImageIndex(selectedImageIndex => (selectedImageIndex + 1) % (images.length - 1));
				setIsFadeIn(true);
			}, 1000) as unknown as number;

			setIsFadeIn(false);
		};

		const intervalTimeoutId = setInterval(changeImage, 6000);

		return () => {
			clearTimeout(intervalTimeoutId);

			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
		};
	}, [images]);

	const selectedImgSrc = images[selectedImageIndex];

	return (
		<div className={styles.noContent}>
			<img className={styles.animatedImg + " " + (isFadeIn ? styles.imgFadeIn : styles.imgFadeOut)} src={selectedImgSrc} alt=""/>

			<h2>{title}</h2>
			<p>{description}</p>

			<div className={styles.noContentButtons}>
				{children}
			</div>
		</div>
	);
}
