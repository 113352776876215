import React from "react";
import style from "./style.module.css";
import * as Avatar from "@radix-ui/react-avatar";

export const UserAvatar = React.forwardRef(
	(allProps: { avatarUrl: string, username: string }, forwardedRef: React.ForwardedRef<HTMLButtonElement>) => {
		const {avatarUrl, username, ...props} = allProps;

		return (
			<button {...props} ref={forwardedRef} className={style.avatarButton} aria-label="Open settings">
				<span>{username}</span>
				<Avatar.Root>
					<Avatar.Image
						className={style.avatarButton}
						src={avatarUrl}
						referrerPolicy="no-referrer"
						alt={``}
					/>

					<Avatar.Fallback className={style.avatarButtonFallback} delayMs={600}>
						{username.toUpperCase()[0]}
					</Avatar.Fallback>
				</Avatar.Root>
			</button>
		);
	});
