import ReactDOM from 'react-dom/client'
import './main.css'
import './theme.css'
import './elements-global.css'

import * as Sentry from "@sentry/react";

import {createBrowserRouter, RouterProvider, useNavigate} from "react-router-dom";
import {AuthenticationForm, loader as authLoader} from "./routes/auth";
import {Account} from "./routes/account/account";
import {App} from "./routes/app";
import {authRequiredLoader, v2UserRequiredLoader} from "@toolbox/utils";
import {StrictMode} from "react";
import {action as createStoryAction, CreateStory} from "./routes/create-story";
import {loader as sessionLoader, Session} from "./routes/session";
import {EmptyContent} from "@component/EmptyContent";
import ErrorImg from "@images/png/512/🫤.png";
import {Stories} from "./routes/stories/stories.tsx";
import {loader as storiesLoader} from "./routes/stories/route.ts";
import {loader as storyLoader, Story} from "./routes/story/index.ts";

Sentry.init({
	dsn: "https://0f0bc7620fa7ea1f251c2bfc62659b65@o4506134766026752.ingest.sentry.io/4506135070572544",
});

export const DefaultErrorElement = () => {
	const navigate = useNavigate();

	return (
		<EmptyContent
			title={"Whoops!"}
			description={"An error occurred. Go back and try again."}
			imgSrc={ErrorImg}
		>
			<button onClick={() => navigate(-1)}>Go back</button>
		</EmptyContent>
	);
};

const router = createBrowserRouter([
	{
		path: "/",
		element: <App/>,
		errorElement: <DefaultErrorElement/>,
		children: [
			{
				path: "accounts/:action?",
				element: <AuthenticationForm/>,
				errorElement: <DefaultErrorElement/>,
				loader: authLoader
			},
			{
				path: "account",
				loader: authRequiredLoader,
				element: <Account/>,
				errorElement: <DefaultErrorElement/>,
			},
			{
				path: "new",
				action: createStoryAction,
				loader: v2UserRequiredLoader,
				element: <CreateStory/>,
				errorElement: <DefaultErrorElement/>,
			},
			{
				path: "session/:sessionId",
				loader: sessionLoader,
				element: <Session/>,
				errorElement: <DefaultErrorElement/>,
			},
			{
				path: "stories",
				loader: storiesLoader,
				element: <Stories/>,
				errorElement: <DefaultErrorElement/>,
			},
			{
				path: "story/:sessionId",
				loader: storyLoader,
				element: <Story/>,
				errorElement: <DefaultErrorElement/>,
			},
		],
	},
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<StrictMode>
		<RouterProvider router={router}/>
	</StrictMode>
);
