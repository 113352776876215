import {useCallback} from 'react';
import {error, log} from '@toolbox/logger.ts';
import * as Auth from '@app/apis/accounts.ts';
import {Credentials, IdentityProvider, Tokens, UserDto} from '@app/apis/accounts.ts';
import {Optional} from "typescript-optional";
import {useNavigate} from "react-router-dom";
import {createJSONStorage, devtools, persist} from "zustand/middleware";
import {createStore, useStore} from "zustand";
import Analytics, {APP_EVENTS} from "@app/analytics.ts";

import {useGoogleAuthentication} from "@component/OAuthSignInButton/useGoogleAuthentication.tsx";
import {indexedDBStorage} from "@app/storage.ts";
import {useMicrosoftAuthentication} from "@component/OAuthSignInButton/useMicrosoftAuthentication.tsx";

export interface Authentication {
	credentials?: Credentials;
	isAuthenticating: boolean;
	isAuthenticated: boolean;
	isLoading: boolean;
	isSaving: boolean;
	_hasHydrated: boolean;
	setHasHydrated: (hasHydrated: boolean) => void;

	getAccessToken: () => Optional<string>;
	externalRefreshToken: () => Promise<string | void>;

	setIsLoading: (isLoading: boolean) => void;
	setIsSaving: (isSaving: boolean) => void;
	setCredentials: (credentials: Credentials) => void;
	resetCredentials: () => void;
	setNewTokens: (tokens: Tokens) => void;
	setUserData: (user: UserDto) => void;
}

export const authStore = createStore<Authentication>()(
	devtools(
		persist(
			(set, get) => ({
				credentials: undefined,
				isAuthenticating: false,
				isAuthenticated: false,
				isLoading: false,
				isSaving: false,
				_hasHydrated: false,
				setHasHydrated: (hasHydrated: boolean) => {
					set({
						_hasHydrated: hasHydrated,
						isLoading: false,
						isAuthenticating: false,
						isSaving: false,
					});
				},
				getAccessToken: () => Optional.ofNullable(get().credentials?.accessToken),
				setCredentials: (credentials: Credentials) => set(state => {
					return {
						...state,
						credentials,
						isAuthenticated: true
					};
				}),
				resetCredentials: () => set(state => ({...state, credentials: undefined, isAuthenticated: false})),
				setIsLoading: (isLoading: boolean) => set(state => ({...state, isLoading})),
				setIsSaving: (isSaving: boolean) => set(state => ({...state, isSaving})),
				setNewTokens: (tokens: Tokens) => set(state => {
					if (!state.credentials) {
						throw new Error('Cannot set new tokens without credentials');
					}

					const credentials = state.credentials;

					return {
						...state,
						credentials: {
							...credentials,
							...tokens
						},
					};
				}),

				setUserData: (user: UserDto) => set(state => {
					if (!state.credentials) {
						throw new Error('Cannot set user data without credentials');
					}

					return {
						...state,
						credentials: {
							...state.credentials,
							user
						},
					}
				}),

				externalRefreshToken: async () => {
					const credentials = get().credentials;

					if (!credentials) {
						error('Attempt to refresh tokens without credentials');

						get().resetCredentials();
						window.location.reload();

						return;
					}

					return await Auth.refreshToken(credentials.refreshToken, credentials.accessToken)
						.then(tokens => {
							log('Tokens refreshed: ', tokens);

							get().setNewTokens(tokens);

							return tokens.accessToken;
						})
						.catch(() => {
							log('Failed to refresh tokens');

							get().resetCredentials();
							window.location.reload();
						});
				}
			}),
			{
				name: 'authentication',
				storage: createJSONStorage(() => indexedDBStorage),

				onRehydrateStorage: () => (state) => {
					if (!state) {
						return;
					}

					if (!state.credentials) {
						state.setHasHydrated(true);

						return;
					}

					const {
						credentials,
						setNewTokens,
						resetCredentials,
					} = state;

					if (
						isAccessTokenExpired(credentials.accessToken, credentials.expiresAt)
					) {
						refreshToken(credentials, setNewTokens, resetCredentials)
							.then(success => {
								log('Refresh tokens success: ', success);
							})
							.finally(() => {
								state.setHasHydrated(true);
							});
					} else {
						state.setHasHydrated(true);
					}
				}
			}
		)
	)
);

export const useAuthenticationStore = (selector: (state: Authentication) => unknown) => useStore(authStore, selector);

export function useAuthentication(isContextLogIn = true): {
	isAuthenticated: boolean;
	isLoading: boolean;
	authWithOAuth: (idp: IdentityProvider) => void;
	signOut: () => void;
} {
	const authStore = useAuthenticationStore((state) => {
		return {
			isAuthenticated: state.isAuthenticated,
			isLoading: state.isLoading,
			setIsLoading: state.setIsLoading,
			setCredentials: state.setCredentials,
			resetCredentials: state.resetCredentials,
		};
	}) as Pick<Authentication, 'isAuthenticated' | 'isLoading' | 'setIsLoading' | 'setCredentials' | 'resetCredentials'>;

	const {
		isAuthenticated,
		isLoading,
		setIsLoading,
		setCredentials,
		resetCredentials,
	} = authStore;

	const navigate = useNavigate();

	const handleSignInResult = useCallback((idp: IdentityProvider, idToken: string) => {
		Auth.authWithOAuth(idToken, idp)
			.then(credentials => {
				setCredentials(credentials);
			})
			.then(() => {
				Analytics.logEvent(isContextLogIn ? APP_EVENTS.LOGIN : APP_EVENTS.SIGNUP);

				navigate('/');
			})
			.catch((error) => {
				log(error);
				resetCredentials();

				navigate(".", {
					replace: true,
					state: {
						error: error.message
					}
				});
			})
			.finally(() => {
				setIsLoading(false)
			});
	}, [isContextLogIn, navigate, resetCredentials, setCredentials, setIsLoading]);

	const {openPrompt: openGooglePrompt} = useGoogleAuthentication(isContextLogIn, handleSignInResult);
	const {openPrompt: openMsPrompt} = useMicrosoftAuthentication(isContextLogIn, handleSignInResult);

	const authWithOAuth = useCallback((idp: IdentityProvider) => {
		setIsLoading(true)

		const openPrompt = idp === IdentityProvider.Google ? openGooglePrompt : openMsPrompt;

		openPrompt()
			.catch((e) => {
				setIsLoading(false);

				navigate(".", {
					replace: true,
					state: {
						error: e.message.includes("user_cancelled") ? undefined : e.message
					}
				});
			});
	}, [navigate, openGooglePrompt, openMsPrompt, setIsLoading]);

	const signOut = useCallback(() => {
		resetCredentials();
	}, [resetCredentials]);

	return {
		isAuthenticated: isAuthenticated,
		isLoading: isLoading,
		authWithOAuth,
		signOut,
	};
}

export function useDeleteAccount(): {
	isLoading: boolean;
	verifyAndDeleteAccount: (idp: IdentityProvider) => void;
} {
	const authStore = useAuthenticationStore((state) => {
		return {
			isLoading: state.isLoading,
			getAccessToken: state.getAccessToken,
			setIsLoading: state.setIsLoading,
			resetCredentials: state.resetCredentials,
		};
	}) as Pick<Authentication, 'isLoading' | 'getAccessToken' | 'setIsLoading' | 'resetCredentials'>;

	const {
		isLoading,
		getAccessToken,
		setIsLoading,
		resetCredentials,
	} = authStore;

	const navigate = useNavigate();

	const authWithOAuth = useCallback((_idp: IdentityProvider, idToken: string) => {
		const accessToken = getAccessToken();

		if (accessToken.isEmpty()) {
			navigate('/accounts/login');

			return;
		}

		Auth.deleteAccount(idToken, accessToken.get())
			.then(() => {
				Analytics.logEvent(APP_EVENTS.ACCOUNT_DELETE);

				resetCredentials();

				navigate('/');
			}).catch((e) => {
			navigate(".", {
				replace: true,
				state: {
					error: e.message
				}
			});
		})
			.finally(() => {
				setIsLoading(false)
			});
	}, [getAccessToken, navigate, resetCredentials, setIsLoading]);

	const {openPrompt: openGooglePrompt} = useGoogleAuthentication(true, authWithOAuth);
	const {openPrompt: openMsPrompt} = useMicrosoftAuthentication(true, authWithOAuth);

	const verifyAndDeleteAccount = useCallback((idp: IdentityProvider) => {
		setIsLoading(true);

		const accessToken = getAccessToken();

		if (accessToken.isEmpty()) {
			navigate('/accounts/login');

			return;
		}

		const openPrompt = idp === IdentityProvider.Google ? openGooglePrompt : openMsPrompt;

		openPrompt().catch((e) => {
			navigate(".", {
				replace: true,
				state: {
					error: e.message
				}
			});
		});
	}, [getAccessToken, navigate, openGooglePrompt, openMsPrompt, setIsLoading]);

	return {
		isLoading: isLoading,
		verifyAndDeleteAccount,
	};
}

export function useLogout(): {
	logOut: () => void;
} {
	const authStore = useAuthenticationStore((state) => ({
		resetCredentials: state.resetCredentials,
	})) as Pick<Authentication, 'resetCredentials'>;

	const {
		resetCredentials,
	} = authStore;

	const navigate = useNavigate();

	const logOut = useCallback(() => {
		Analytics.logEvent(APP_EVENTS.LOGOUT);

		resetCredentials();

		navigate('/accounts/login');
	}, [navigate, resetCredentials]);

	return {
		logOut,
	};
}

export function useRefreshUserData() {
	const {
		setUserData,
		credentials
	} = useAuthenticationStore((state) => {
		return {
			setUserData: state.setUserData,
			credentials: state.credentials,
		};
	}) as Pick<Authentication, 'setUserData' | 'credentials'>;

	return useCallback(() => {
		if (!credentials) {
			error("Cannot refresh tokens without credentials");

			return;
		}

		Auth.getUser(credentials.accessToken)
			.then(userDto => {
				log('Refreshed user data: ', userDto);

				setUserData(userDto);
			})
			.catch(() => {
				log('Failed to refresh user data');
			});
	}, [credentials, setUserData]);
}

async function refreshToken(
	credentials: Credentials,
	setNewTokens: (tokens: Tokens) => void,
	resetCredentials: () => void,
): Promise<boolean> {
	log('Old credentials: ', credentials);
	log('Refreshing tokens');

	return Auth.refreshToken(credentials.refreshToken, credentials.accessToken)
		.then(tokens => {
			log('Tokens refreshed: ', tokens);

			setNewTokens(tokens);

			return true;
		})
		.catch(() => {
			log('Failed to refresh tokens');

			resetCredentials();

			return false;
		});
}

function isAccessTokenExpired(accessToken: string, expiresAt: number): boolean {
	return accessToken !== '' && expiresAt < Date.now() / 1000;
}

export function useAuthenticatedContext(): {
	isAuthenticated: boolean;
	isLoading: boolean;
	authUser: Optional<Credentials['user']>;
} {
	const {
		isAuthenticated,
		isLoading,
		credentials
	} = useAuthenticationStore((state) => {
		return {
			isAuthenticated: state.isAuthenticated,
			isLoading: state.isLoading,
			credentials: state.credentials,
		};
	}) as Pick<Authentication, 'isAuthenticated' | 'isLoading' | 'credentials'>;

	return {
		isAuthenticated: isAuthenticated,
		isLoading: isLoading,
		authUser: Optional.ofNullable(credentials?.user),
	};
}

export function useAuthUser(): Credentials['user'] | undefined {
	const {authUser} = useAuthenticatedContext();

	return authUser.isEmpty() ? undefined : authUser.get();
}

export async function isAuthenticated(): Promise<boolean> {
	if (authStore.getState()._hasHydrated) {
		return authStore.getState().isAuthenticated;
	}

	return new Promise((resolve) => {
		const unsubscribe = authStore.subscribe((state) => {

			if (state._hasHydrated) {
				resolve(state.isAuthenticated);

				unsubscribe();
			}
		});
	});
}

export async function hasV2Access(): Promise<boolean> {
	if (authStore.getState()._hasHydrated) {
		return authStore.getState().credentials?.user.v2_access || false;
	}

	return new Promise((resolve) => {
		const unsubscribe = authStore.subscribe((state) => {

			if (state._hasHydrated) {
				resolve(state.credentials?.user.v2_access || false);

				unsubscribe();
			}
		});
	});
}

export function useV2Access(): boolean {
	const {credentials} = useAuthenticationStore(state => ({
		_hasHydrated: state._hasHydrated,
		isAuthenticated: state.isAuthenticated,
		credentials: state.credentials
	})) as Pick<Authentication, "credentials">;

	return credentials?.user?.v2_access || false;
}

export async function getAccessToken(): Promise<string> {
	const credentials = authStore.getState().credentials;

	if (!credentials) {
		throw new Error('Attempt to get access token without credentials');
	}

	const isExpired = isAccessTokenExpired(credentials.accessToken, credentials.expiresAt);

	if (isExpired) {
		return await authStore.getState().externalRefreshToken() || '';
	}

	return credentials.accessToken;
}
