import {redirect} from "react-router-dom";
import {hasV2Access, isAuthenticated} from "@app/stores/auth";

export async function authRequiredLoader() {
	if (!(await isAuthenticated())) {
		throw redirect('/accounts/login');
	}

	return null;
}

export async function v2UserRequiredLoader() {
	if (!(await isAuthenticated())) {
		throw redirect('/accounts/login');
	}

	if (!(await hasV2Access())) {
		throw redirect('/');
	}

	return null;
}

export function coinWord(coins: number) {
	return pluralizeWord(coins, "coin", "coins");
}

export function pluralizeWord(count: number, singular: string, plural: string) {
	if (count === 1) {
		return singular;
	} else {
		return plural;
	}
}

export function capitalizeText(text: string) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export function addPeriodIfMissing(text: string) {
	return text.endsWith(".") ? text : text + ".";
}
