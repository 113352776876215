import React, {useRef} from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import {IconButton} from "@component/ui/Button";
import styles from "./style.module.css";

interface DialogProps extends DialogPrimitive.DialogProps {
	triggerElement?: React.ReactNode;
	title: string;
	description?: string;
	showCloseButton?: boolean;
	children: React.ReactNode;
	onClose?: () => void;
	width?: string;
	fullscreen?: boolean;
	goBackCloseButton?: boolean;
}

export function Dialog(allProps: DialogProps): React.JSX.Element {
	const {
		triggerElement,
		title,
		description,
		showCloseButton = true,
		goBackCloseButton = false,
		children,
		onClose,
		width,
		fullscreen = false,
		...props
	} = allProps;

	const closeButtonRef = useRef<HTMLButtonElement>(null);

	const showGoBackButton = showCloseButton && goBackCloseButton;

	return (
		<DialogPrimitive.Root {...props}>
			{triggerElement && <DialogPrimitive.Trigger asChild>
				{triggerElement}
			</DialogPrimitive.Trigger>}
			<DialogPrimitive.Portal>
				<DialogPrimitive.Overlay className={styles.background}/>

				<DialogPrimitive.Content asChild onEscapeKeyDown={onClose}>
					<div className={`${styles.container} ${fullscreen ? styles.fullscreen : ""}`} style={{width: width}}>
						{showGoBackButton && (
							<div className={styles.goBackCont}>
								<DialogPrimitive.Close asChild>
									<IconButton
										ref={closeButtonRef}
										symbolId="arrow_back"
										label="close window"
										title="Go back"
										fontSizeRem={1.8}
										className={styles.backButton}
										onClick={onClose}
									/>
								</DialogPrimitive.Close>

								<DialogPrimitive.Title className={styles.title}>
									{title}
								</DialogPrimitive.Title>
							</div>
						)}

						{!showGoBackButton && <DialogPrimitive.Title className={styles.title}>
							{title}
						</DialogPrimitive.Title>}

						{description && <DialogPrimitive.Description className={styles.description}>
							{description}
						</DialogPrimitive.Description>}

						{showCloseButton && !goBackCloseButton && <DialogPrimitive.Close asChild aria-label="Close">
							<IconButton
								ref={closeButtonRef}
								symbolId="close"
								label="close window"
								title="Close"
								className={styles.closeButton}
								onClick={onClose}
							/>
						</DialogPrimitive.Close>}

						{children}
					</div>
				</DialogPrimitive.Content>
			</DialogPrimitive.Portal>
		</DialogPrimitive.Root>
	);
}
