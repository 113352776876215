import React, {useEffect, useState} from 'react';
import styles from './style.module.css';
import {SignInButton} from "@component/OAuthSignInButton";
import {Link, useLoaderData, useLocation} from "react-router-dom";
import {useAuthentication} from "@app/stores/auth";
import {FormType} from "./index.ts";
import {IdentityProvider} from "@app/apis/accounts.ts";

export function AuthenticationForm(): React.JSX.Element {
	const formType = useLoaderData() as FormType;

	const serverError = useLocation().state?.error || '';

	const [isLogin, setIsLogin] = useState(formType === FormType.Login);

	const {
		isLoading,
		authWithOAuth,
	} = useAuthentication(isLogin);

	useEffect(() => {
		if (formType === FormType.Login && isLogin) {
			return;
		}

		if (formType === FormType.Register && !isLogin) {
			return;
		}

		setIsLogin(formType === FormType.Login);
	}, [formType, isLogin]);

	return (
		<div className={`${styles.background}`}>
			<main className={styles.container}>

				<div className={styles.header}>
					<h2>{isLogin ? 'Log in to your account' : 'Create an account'}</h2>

					<Link
						to={`/accounts/${formType === FormType.Login ? "register" : "login"}`}
						replace={true}
						className={styles.toggleBtn}
					>
						{isLogin ? "Don't have an account? Register" : 'Already have an account?'}
					</Link>
				</div>

				<div className={styles.buttons}>
					<SignInButton
						isContextSignIn={isLogin}
						onClick={() => authWithOAuth(IdentityProvider.Google)}
						isDisabled={isLoading}
						center={true}
						idp={IdentityProvider.Google}
					/>

					<SignInButton
						isContextSignIn={isLogin}
						onClick={() => authWithOAuth(IdentityProvider.Microsoft)}
						isDisabled={isLoading}
						center={true}
						idp={IdentityProvider.Microsoft}
					/>
				</div>

				{serverError && <p className={styles.error}>{serverError}</p>}

				<div className={styles.tos}>
					By pressing "Continue", you confirm that you have read and agree to our{' '}
					<a target="_blank" className={styles.toggleBtn} href={"https://raviolstation.com/about/tos"}>
						Terms of Service
					</a>
					{' '}and{' '}
					<a target="_blank" className={styles.toggleBtn} href={"https://raviolstation.com/about/privacy"}>
						Privacy Policy
					</a>.
				</div>
			</main>
		</div>
	);
}
