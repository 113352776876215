import React from "react";
import styles from "./styles.module.css";
import {IconButton} from "@component/ui/Button";
import {useNavigate} from "react-router-dom";

export function SectionCont({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<div className={styles.superCont + (className ? " " + className : "")}>
			{children}
		</div>
	);
}

export function SectionMainContent({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<main className={styles.cont + (className ? " " + className : "")}>
			{children}
		</main>
	);
}

export function SectionTitle({
	title,
	imgSrc,
	showGoBackButton,
}: {
	title: string;
	imgSrc?: string;
	showGoBackButton?: boolean;
}) {
	const navigate = useNavigate();

	return (
		<div className={styles.sectionTitle}>
			{imgSrc && (
				<img src={imgSrc} alt={""}/>
			)}

			{showGoBackButton && (
				<IconButton
					label={"Go back"}
					fontSizeRem={1.8}
					iconProps={{
						symbolId: "arrow_back",
						offset: {
							x: -3,
							y: 1
						}
					}}
					onClick={() => {
						navigate(-1)
					}}
				/>
			)}

			<h2>{title}</h2>
		</div>
	);
}
