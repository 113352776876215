import {useOutlet} from "react-router-dom";

import styles from './style.module.css';
import {BUILD_ID} from "../../config.ts";
import {Authentication, useAuthenticationStore, useV2Access} from "@app/stores/auth";
import {useYBugWidget} from "./useYBugWidget.ts";
import {GlobalToast} from "@app/providers/toast/provider.tsx";
import {AccessInfoContextProvider} from "@app/providers/access-info/provider.tsx";

import {LinkButton} from "@component/ui/Button";
import {AnimatedEmptyContent} from "@component/EmptyContent";
import DashboardImg from "@images/png/512/🤓.png";
import {TopBar} from "./TopBar";
import {Landing} from "./Landing";
import {NewStoryButtonLink} from "@component/NewStoryButtonLink";

import Img0 from "@images/png/512/transition/Face Holding Back Tears.png";
import Img1 from "@images/png/512/transition/Crying Cat.png";
import Img2 from "@images/png/512/transition/Face with Peeking Eye.png";
import Img3 from "@images/png/512/transition/Rolling on the Floor Laughing.png";
import Img4 from "@images/png/512/transition/Heart with Arrow.png";
import Img5 from "@images/png/512/transition/Ghost.png";
import Img6 from "@images/png/512/transition/Biting Lip.png";
import Img7 from "@images/png/512/transition/Brain.png";
import Img8 from "@images/png/512/🔥.png";
import Img9 from "@images/png/512/transition/Star-Struck.png";
import {useMemo} from "react";

export function App() {
	const {
		_hasHydrated,
		isAuthenticated,
	} = useAuthenticationStore(state => ({
		_hasHydrated: state._hasHydrated,
		isAuthenticated: state.isAuthenticated
	})) as Pick<Authentication, "_hasHydrated" | "isAuthenticated">;

	useYBugWidget(isAuthenticated);

	const hasV2Access = useV2Access();

	if (!_hasHydrated) {
		return null;
	}

	return (
		<>
			<AccessInfoContextProvider>
				<TopBar largeTopBar={isAuthenticated} hasV2Access={hasV2Access}></TopBar>

				<Body isAuthenticated={isAuthenticated} hasV2Access={hasV2Access}/>
			</AccessInfoContextProvider>

			<Footer/>

			<div className={styles.buildVersion}>build_{BUILD_ID}</div>
			<GlobalToast/>
		</>
	);
}

interface BodyProps {
	isAuthenticated: boolean;
	hasV2Access: boolean;
}

function Body({isAuthenticated, hasV2Access}: BodyProps) {
	const outlet = useOutlet();

	const hasNoRouteOutlet = !outlet;

	const showLanding = !isAuthenticated && hasNoRouteOutlet;
	const showDashboard = isAuthenticated && hasNoRouteOutlet;

	const dashboardDescription = hasV2Access ? "Get started by finding your"
		: "Please contact us at storyscape@raviolstation.com to get web access.";

	const images = useMemo(() => {
		return [DashboardImg, Img0, Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9]
			.sort(() => Math.random() - 0.5);
	}, []);

	return (
		<div className={styles.cont}>
			{showLanding && <Landing/>}

			{showDashboard && (
				<AnimatedEmptyContent
					title={"Quick Navigation"}
					description={dashboardDescription}
					images={images}
				>
					<LinkButton to="/account" variant="secondary">Account</LinkButton>
					{hasV2Access && <LinkButton to="/stories" variant="secondary">Stories</LinkButton>}
					{hasV2Access && <NewStoryButtonLink/>}
				</AnimatedEmptyContent>
			)}

			{outlet}
		</div>
	);
}

export function Footer() {
	return (
		<footer className={styles.footer}>
			<div className={styles.footerLinks}>
				<a href={"https://raviolstation.com/about/privacy"}>Privacy Policy</a>
				<a href={"https://raviolstation.com/about/tos"}>Terms of Service</a>
				<a href={"/thirdparty.html"}>Third-Party Licenses</a>
			</div>

			<div className={styles.footerLast}>
				© 2024 Raviolstation
			</div>
		</footer>
	);
}

export default App;
