import * as Server from "@app/apis/sessions.models.ts";
import {CheckAccessResponse} from "@app/apis/sessions.models.ts";
import {AccessInfoContext, AccessInfoContextType} from "@app/providers/access-info/provider.tsx";
import {checkAccess} from "@app/apis/sessions.ts";
import {useCallback, useContext} from "react";

export type SetIsCheckingAccessAction = {
	type: 'SET_IS_CHECKING_ACCESS';
	payload: boolean;
};

export type UpdateAccessInfoAction = {
	type: 'UPDATE_ACCESS_INFO';
	payload: CheckAccessResponse;
};

export type Action = SetIsCheckingAccessAction | UpdateAccessInfoAction;

export type AccessInfoState = {
	accessInfo: Server.CheckAccessResponse;
	isCheckingAccess: boolean;
};

export function reducer(state: AccessInfoState, action: Action): AccessInfoState {
	switch (action.type) {
		case 'UPDATE_ACCESS_INFO': {
			return {
				...state,
				accessInfo: action.payload,
			};
		}

		case 'SET_IS_CHECKING_ACCESS': {
			return {
				...state,
				isCheckingAccess: action.payload,
			};
		}

		default:
			return state;
	}
}

export async function updateAccessInfo(
	dispatch: AccessInfoContextType['dispatch']
): Promise<CheckAccessResponse> {
	dispatch({type: 'SET_IS_CHECKING_ACCESS', payload: true});

	return checkAccess()
		.then(res => {
			dispatch({
				type: 'UPDATE_ACCESS_INFO',
				payload: res,
			});

			return res;
		}).finally(() => {
			dispatch({type: 'SET_IS_CHECKING_ACCESS', payload: false});
		});
}

export function useUpdateAccessInfo(): () => Promise<CheckAccessResponse> {
	const {dispatch} = useAccessInfoContext();

	return useCallback(() => updateAccessInfo(dispatch), [dispatch]);
}

export function useAccessInfoContext() {
	const context = useContext(AccessInfoContext);

	if (!context) {
		throw new Error('useAccessInfoContext must be used within an AccessInfoContextProvider');
	}

	return context;
}

export function useRemainingCoins(): number {
	const {state: {accessInfo: {remaining}}} = useAccessInfoContext();

	return remaining;
}
