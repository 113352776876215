import {useLocation, useNavigate} from "react-router-dom";

import styles from './account.module.css';
import React, {useState} from "react";
import {SignInButton} from "@component/OAuthSignInButton";
import {useAuthenticatedContext, useDeleteAccount} from "@app/stores/auth";
import {GetMoreCoinsButton} from "@component/CoinsButton";
import newStoryRouteStyles from "../create-story/styles.module.css";
import * as Switch from "@radix-ui/react-switch";

import {useRemainingCoins} from "@app/providers/access-info";
import {coinWord} from "@toolbox/utils.ts";
import {SectionCont, SectionMainContent, SectionTitle} from "@component/Layout";
import {IdentityProvider} from "@app/apis/accounts.ts";

export function Account(): React.JSX.Element {
	const {isLoading, verifyAndDeleteAccount} = useDeleteAccount();
	const {authUser, isAuthenticated} = useAuthenticatedContext();
	const remainingCoins = useRemainingCoins();
	const serverError = useLocation().state?.error || '';

	const [understands, setUnderstands] = useState<boolean>(false);

	const navigate = useNavigate();

	if (!isAuthenticated) {
		return <div/>;
	}

	const {idp, isPro} = authUser.get();

	const isGoogleAccount = idp === IdentityProvider.Google;
	const identityProviderName = isGoogleAccount ? "Google" : "Microsoft";

	return (
		<SectionCont className={styles.cont}>
			<SectionMainContent className={styles.form}>
				<SectionTitle title={"Account"} showGoBackButton={true}/>

				<div>
					<h3>Coins</h3>
					<p>{`You have ${remainingCoins} ${coinWord(remainingCoins)}.`}</p>

					<GetMoreCoinsButton label={"Get more coins"} remaining={remainingCoins} isPro={isPro}/>
				</div>

				<h3>Delete account</h3>

				<p className={styles.deleteAccountText}>
					Deleting your account will result in the permanent loss of your saved data, including your profile information
					and saved settings.
				</p>
				<p className={styles.deleteAccountText}>
					You will lose access to your coins and any saved stories.
				</p>

				<label>
					{"Do you agree?"}

					<Switch.Root
						className={"secondaryButton " + newStoryRouteStyles.SwitchRoot}
						id="terms"
						aria-label={"switch to accept terms"}
						checked={understands}
						onCheckedChange={(isChecked: boolean) => {
							setUnderstands(isChecked)
						}}
					>
						<Switch.Thumb className={newStoryRouteStyles.SwitchThumb}/>
					</Switch.Root>
				</label>

				<p className={styles.deleteAccountText}>
					{`For your security and to confirm that you are the account owner, we require you to sign in with ${identityProviderName}
					again before we can proceed with the deletion process.`}
				</p>

				<p className={styles.deleteAccountContinueText}>Sign in to continue.</p>

				<div className={styles.form}>
					<SignInButton
						isContextSignIn={true}
						isDisabled={!understands || isLoading}
						onClick={() => verifyAndDeleteAccount(idp)}
						center={false}
						idp={idp}
					/>
				</div>

				<button onClick={() => navigate(-1)}>Go back</button>

				{serverError && <p className={styles.error}>{serverError}</p>}
			</SectionMainContent>
		</SectionCont>
	);
}
