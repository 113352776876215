import Dexie from 'dexie';
import {error} from '@toolbox/logger';
import {StateStorage} from "zustand/middleware";

const db = new Dexie('storyscapeai');
db.version(2).stores({ kv: 'key' });

export async function set(key: string, value: string) {
	try {
		await db.table('kv').put({key, value});
	} catch (e) {
		error(`Error setting "${key}" to storage: `, e);
	}
}

export async function remove(key: string) {
	try {
		await db.table('kv').delete(key);
	} catch (e) {
		error(`Error removing "${key}" from storage: `, e);
	}
}

export async function get(key: string): Promise<string | null> {
	try {
		const item = await db.table('kv').get(key);

		return item?.value ?? null;
	} catch (e) {
		error(`Error getting "${key}" from storage: `, e);
	}

	return null;
}

export const indexedDBStorage: StateStorage = {
	getItem: async (name: string): Promise<string | null> => {
		return (await get(name)) || null
	},
	setItem: async (name: string, value: string): Promise<void> => {
		await set(name, value)
	},
	removeItem: async (name: string): Promise<void> => {
		await remove(name)
	},
}
