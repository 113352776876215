import Dexie from 'dexie';
import {SessionState} from "@app/providers/session/dtos.ts";

class SessionsDB extends Dexie {
	sessions: Dexie.Table<SessionState, string>;

	constructor() {
		super('StoryScapeAI-DB');

		this.version(1).stores({
			sessions: 'id',
		});

		this.sessions = this.table('sessions');
	}
}

const db = new SessionsDB();

export async function hasSession(sessionId: string): Promise<boolean> {
	const session = await db.sessions.get(sessionId);

	return !!session;
}

export function addSession(session: SessionState) {
	return db.sessions.add(session);
}

export function getSession(sessionId: string): Promise<SessionState|undefined> {
	return db.sessions.get(sessionId);
}

export function updateSession(sessionId: string, session: Partial<SessionState>) {
	return db.sessions.update(sessionId, session);
}

export function deleteSession(sessionId: string) {
	return db.sessions.delete(sessionId);
}

export function getAll(): Promise<SessionState[]> {
	return db.sessions.toArray();
}
