import style from "./style.module.css";
import {Link} from "react-router-dom";
import React from "react";

export function AppName({isProUser}: {isProUser: boolean}): React.JSX.Element {
	return (
		<Link to="/" className={style.appName}>
			<div className={style.logoAndAppName}>
				<div className={style.logoImage}/>
				<h1>StoryScape AI</h1>

				<div className={style.beta}>{isProUser ? "Premium user" : "Preview"}</div>
			</div>
		</Link>
	);
}
