import styles from "./styles.module.css";
import NotFoundImg from "@images/png/512/🙀.png";
import {Chapter} from "../Chapter";
import {SettingsButton} from "../SettingsButton";
import {NextChapterController} from "../NextChapterController";
import {EmptyContent} from "@component/EmptyContent";
import NoMoreCreditsImage from "@images/png/512/Anguished Face.png";
import {Checkout} from "@component/Checkout";
import {ChapterDTO} from "@app/providers/session/dtos.ts";
import React, {useEffect} from "react";
import {BackgroundImage} from "../BlurredBackground";
import Analytics, {APP_EVENTS} from "@app/analytics.ts";
import {useAccessInfoContext} from "@app/providers/access-info";
import {useSessionContext} from "@app/providers/session/hooks.ts";
import {NewStoryButtonLink} from "@component/NewStoryButtonLink";
import {useReadingSettings} from "@app/providers/reading-settings";
import {useNavigate} from "react-router-dom";

export function SessionComponent() {
	const [readingSettings] = useReadingSettings();
	const {
		state: {
			accessInfo: {
				remaining
			}
		}
	} = useAccessInfoContext();

	const { state: session } = useSessionContext();

	const {
		title,
		chapters,
		isLastChapter,
	} = session;

	const hasRemainingUses = remaining > 0;

	const navigate = useNavigate();

	if (session.error != null) {
		return (
			<EmptyContent
				title={session.error.title}
				description={session.error.description}
				imgSrc={NotFoundImg}
			>
				<button
					className={"secondaryButton"}
					onClick={() => navigate(-1)}
				>
					Go back
				</button>
			</EmptyContent>
		);
	}

	if (!session.isHydrated) {
		return null;
	}

	return (
		<StoryContainer>
			<BackgroundImage
				chapters={chapters}
				animateBackground={readingSettings.animateBackground}
			/>

			<StoryTitle title={title}/>

			<Chapters
				chapters={chapters}
				fontSizeEm={readingSettings.fontSizeEm}
				readOnly={false}
			>
				{hasRemainingUses && !isLastChapter && <NextChapterController session={session}/>}

				{isLastChapter && (
					<div className={styles.exitButtons}>
						<button
							className={"secondaryButton"}
							onClick={() => navigate(-1)}
						>
							Go back
						</button>

						<NewStoryButtonLink/>
					</div>
				)}
			</Chapters>

			{!hasRemainingUses && !isLastChapter && <NotEnoughCoins/>}

			<SettingsButton/>
		</StoryContainer>
	);
}

export function StoryContainer(props: { children: React.ReactNode }) {
	return (
		<section className={styles.storyFrame}>
			{props.children}
		</section>
	);
}

export function Chapters(props: {
	chapters: ChapterDTO[];
	fontSizeEm: number;
	children?: React.ReactNode;
	readOnly: boolean;
}) {
	const {
		chapters,
		fontSizeEm,
		children,
		readOnly,
	} = props;

	return (
		<div
			className={styles.chaptersCont}
			style={{
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				"--chapter-font-size-em": fontSizeEm + "em"
			}}
		>
			{chapters.map((chapter, chapterIndex) => (
				<Chapter
					key={chapterIndex}
					chapterIndex={chapterIndex}
					chapter={chapter}
					readOnly={readOnly}
				/>
			))}

			{children}
		</div>
	);
}

export function StoryTitle({title}: { title: string }) {
	return (
		<header className={styles.storyTitle}>
			<h2>{title}</h2>
		</header>
	);
}

function NotEnoughCoins() {
	useEffect(() => {
		Analytics.logEvent(APP_EVENTS.VIEW_CART, {
			from: "session"
		});
	}, []);

	return (
		<div className={styles.noCoinsCont}>
			<EmptyContent
				title={"No coins left"}
				description={"You've run out of coins. Get more to keep enjoying the story."}
				imgSrc={NoMoreCreditsImage}
				symbolId={"money"}
			/>

			<Checkout/>
		</div>
	);
}
