import React from "react";
import styles from "./style.module.css";
import * as Popover from "@radix-ui/react-popover";

interface PopOverProps extends Popover.PopoverProps {
	triggerElement?: React.ReactNode;
	children: React.ReactNode;
	width?: string;
	showArrow?: boolean;
	sideOffset?: number;
	contentProps?: Popover.PopoverContentProps;
}

export function PopOver(allProps: PopOverProps): React.JSX.Element {
	const {
		triggerElement,
		children,
		width,
		showArrow = true,
		sideOffset = 5,
		contentProps,
		...props
	} = allProps;

	return (
		<Popover.Root {...props}>
			{triggerElement && <Popover.Trigger asChild>
				{triggerElement}
			</Popover.Trigger>}

			<Popover.Portal>
				<Popover.Content
					className={styles.content}
					sideOffset={sideOffset}
					style={{width: width}}
					{...contentProps}
				>
					{children}

					{showArrow && <Popover.Arrow className={styles.arrow}/>}
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	);
}
