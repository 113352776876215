import {useCallback} from "react";
import {MS_IDENTITY_CLIENT_ID, OAUTH_REDIRECT_URL} from "../../config.ts";
import {PublicClientApplication} from "@azure/msal-browser";
import {IdentityProvider} from "@app/apis/accounts.ts";

export function useMicrosoftAuthentication(
	_isContextSignIn: boolean,
	handleSignInResult: (idp: IdentityProvider, idToken: string) => void,
) {
	const open = useCallback(async () => {
		const publicClientApplication = new PublicClientApplication({
			auth: {
				clientId: MS_IDENTITY_CLIENT_ID,
				authority: 'https://login.microsoftonline.com/consumers',
				redirectUri: OAUTH_REDIRECT_URL,
			},
		});

		await publicClientApplication.initialize();

		const authResult = await publicClientApplication.loginPopup({
			scopes: ["profile"],
		});

		if (authResult.idToken) {
			handleSignInResult(IdentityProvider.Microsoft, authResult.idToken);
		} else {
			throw new Error('An error occurred');
		}
	}, [handleSignInResult]);

	return {openPrompt: open};
}
