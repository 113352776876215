import React from "react";
import topbarStyles from "../../routes/app/TopBar/style.module.css";
import style from "./style.module.css";
import * as Avatar from "@radix-ui/react-avatar";

interface UserIdCard {
	avatarUrl: string;
	username: string;
	email: string;
}

export function UserIdCard({avatarUrl, username, email}: UserIdCard): React.JSX.Element {
	return (
		<div className={style.cont} tabIndex={-1}>
			<Avatar.Root>
				<Avatar.Image
					className={topbarStyles.avatarButton}
					src={avatarUrl}
					referrerPolicy="no-referrer"
					alt={``}
				/>

				<Avatar.Fallback className={topbarStyles.letterAvatar}>
					{username.toUpperCase()[0]}
				</Avatar.Fallback>
			</Avatar.Root>

			<div>
				<p className={style.username}>{`${username}`}</p>
				<p className={style.email}>{`${email}`}</p>
			</div>
		</div>
	);
}
