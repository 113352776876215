import {useCallback, useState} from "react";
import {BreadcrumbButton} from "@component/BreadcrumbButton";
import Coin from "@images/emojis/coin_3d.png";
import {Checkout} from "@component/Checkout";
import styles from "./styles.module.css";
import {Dialog} from "@component/ui/Dialog";
import Analytics, {APP_EVENTS} from "@app/analytics.ts";
import {coinWord} from "@toolbox/utils.ts";

interface CoinsButtonProps {
	remaining: number;
	label?: string;
	triggerType?: "button" | "breadcrumb";
	isPro?: boolean;
	showLabel?: boolean;
}

function CoinsButton({remaining, label, triggerType, showLabel = true, isPro = false}: CoinsButtonProps) {
	const [isOpen, setIsOpen] = useState(false);

	const onSuccess = useCallback(() => {
		setIsOpen(false);
	}, []);

	const triggerElement = triggerType === "breadcrumb" ? (
		<BreadcrumbButton
			value={label ?? `${remaining} ${coinWord(remaining)}`}
			iconProps={{
				imgSrc: Coin,
				offset: {
					x: 0,
					y: 0
				}
			}}
			onClick={() => {
				setIsOpen(true);
				Analytics.logEvent(APP_EVENTS.VIEW_CART, {from: "topbar_tokens"});
			}}
		/>
	) : (
		<button
			onClick={() => {
				setIsOpen(true);
				Analytics.logEvent(APP_EVENTS.VIEW_CART, {from: "account_coins"});
			}}
		>
			{label}
		</button>
	);

	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				setIsOpen(false);
			}}
			triggerElement={triggerElement}
			showCloseButton={true}
			width={"fit-content"}
			title={`You have ${remaining} ${coinWord(remaining)}.`}
		>
			{
				showLabel && !isPro && <p>
					{"✨ Buying coins makes you a Premium user, granting access to additional features."}
				</p>
			}

			{
				showLabel && isPro && <p>
					{"🎉 You are a premium user! Thanks for supporting us."}
				</p>
			}

			<div className={styles.popupCont}>
				<Checkout onSuccess={onSuccess}/>
			</div>
		</Dialog>
	);
}

export function TopBarCoinsButton({remaining, label, showLabel, isPro}: CoinsButtonProps) {
	return <CoinsButton remaining={remaining} label={label} triggerType="breadcrumb" showLabel={showLabel} isPro={isPro}/>;
}

export function GetMoreCoinsButton({remaining, label, showLabel, isPro}: CoinsButtonProps) {
	return <CoinsButton remaining={remaining} label={label} triggerType="button" showLabel={showLabel} isPro={isPro}/>;
}
