import styles from "./styles.module.css";
import React from "react";

export function Squiggle() {
	return (
		<>
			<svg width="322" height="18" viewBox="0 0 483 27" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.squiggleLine}>
				<path
					d="M0 0C11.236 0 22.219 3.55006 32.8769 10.2597C42.3392 16.2167 51.2389 19 60.1231 19C69.0074 19 78.3822 16.2167 87.8445 10.2597C98.5024 3.55006 109.485 0 120.721 0C131.957 0 142.94 3.55006 153.598 10.2597C163.061 16.2167 172.198 19 181.082 19C189.966 19 199.104 16.2167 208.566 10.2597C219.224 3.55006 230.207 0 241.443 0C252.679 0 263.662 3.55006 274.32 10.2597C283.782 16.2167 292.919 19 301.803 19C310.688 19 319.825 16.2167 329.287 10.2597C339.945 3.55006 350.928 0 362.164 0C373.4 0 384.383 3.55006 395.041 10.2597C404.503 16.2167 413.641 19 422.525 19C431.409 19 440.546 16.2167 450.009 10.2597C460.667 3.55006 471.65 0 482.886 0V8C474.001 8 464.864 10.7833 455.402 16.7403C444.744 23.4499 433.761 27 422.525 27C411.289 27 400.306 23.4499 389.648 16.7403C380.186 10.7833 371.048 8 362.164 8C353.28 8 344.143 10.7833 334.68 16.7403C324.022 23.4499 313.039 27 301.803 27C290.567 27 279.585 23.4499 268.927 16.7403C259.464 10.7833 250.327 8 241.443 8C232.559 8 223.421 10.7833 213.959 16.7403C203.301 23.4499 192.318 27 181.082 27C169.846 27 158.863 23.4499 148.205 16.7403C138.743 10.7833 129.606 8 120.721 8C111.837 8 102.7 10.7833 93.2376 16.7403C82.5797 23.4499 71.3591 27 60.1231 27C48.8872 27 38.1417 23.4499 27.4838 16.7403C18.0215 10.7833 8.88426 8 0 8V0Z"
					fill="url(#linear-gradient_:R79i:)"></path>
				<defs>
					<linearGradient id="linear-gradient_:R79i:" x1="73.7411" y1="13.0001" x2="456.719" y2="13.0001"
													gradientUnits="userSpaceOnUse">
						<stop stopColor="#A467D5"></stop>
						<stop offset="0.0001" stopColor="#A467D5"></stop>
						<stop offset="0.285152" stopColor="#D8719D"></stop>
						<stop offset="0.716028" stopColor="#FBBC99"></stop>
						<stop offset="1" stopColor="#A467D5"></stop>
					</linearGradient>
				</defs>
			</svg>

			<svg width="322" height="18" viewBox="0 0 483 27" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.squiggleLine}>
				<path
					d="M0 0C11.236 0 22.219 3.55006 32.8769 10.2597C42.3392 16.2167 51.2389 19 60.1231 19C69.0074 19 78.3822 16.2167 87.8445 10.2597C98.5024 3.55006 109.485 0 120.721 0C131.957 0 142.94 3.55006 153.598 10.2597C163.061 16.2167 172.198 19 181.082 19C189.966 19 199.104 16.2167 208.566 10.2597C219.224 3.55006 230.207 0 241.443 0C252.679 0 263.662 3.55006 274.32 10.2597C283.782 16.2167 292.919 19 301.803 19C310.688 19 319.825 16.2167 329.287 10.2597C339.945 3.55006 350.928 0 362.164 0C373.4 0 384.383 3.55006 395.041 10.2597C404.503 16.2167 413.641 19 422.525 19C431.409 19 440.546 16.2167 450.009 10.2597C460.667 3.55006 471.65 0 482.886 0V8C474.001 8 464.864 10.7833 455.402 16.7403C444.744 23.4499 433.761 27 422.525 27C411.289 27 400.306 23.4499 389.648 16.7403C380.186 10.7833 371.048 8 362.164 8C353.28 8 344.143 10.7833 334.68 16.7403C324.022 23.4499 313.039 27 301.803 27C290.567 27 279.585 23.4499 268.927 16.7403C259.464 10.7833 250.327 8 241.443 8C232.559 8 223.421 10.7833 213.959 16.7403C203.301 23.4499 192.318 27 181.082 27C169.846 27 158.863 23.4499 148.205 16.7403C138.743 10.7833 129.606 8 120.721 8C111.837 8 102.7 10.7833 93.2376 16.7403C82.5797 23.4499 71.3591 27 60.1231 27C48.8872 27 38.1417 23.4499 27.4838 16.7403C18.0215 10.7833 8.88426 8 0 8V0Z"
					fill="url(#linear-gradient_:Rb9i:)"></path>
				<defs>
					<linearGradient id="linear-gradient_:Rb9i:" x1="73.7411" y1="13.0001" x2="456.719" y2="13.0001"
													gradientUnits="userSpaceOnUse">
						<stop stopColor="#A467D5"></stop>
						<stop offset="0.0001" stopColor="#A467D5"></stop>
						<stop offset="0.285152" stopColor="#D8719D"></stop>
						<stop offset="0.716028" stopColor="#FBBC99"></stop>
						<stop offset="1" stopColor="#A467D5"></stop>
					</linearGradient>
				</defs>
			</svg>

			<svg width="322" height="18" viewBox="0 0 483 27" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.squiggleLine}>
				<path
					d="M0 0C11.236 0 22.219 3.55006 32.8769 10.2597C42.3392 16.2167 51.2389 19 60.1231 19C69.0074 19 78.3822 16.2167 87.8445 10.2597C98.5024 3.55006 109.485 0 120.721 0C131.957 0 142.94 3.55006 153.598 10.2597C163.061 16.2167 172.198 19 181.082 19C189.966 19 199.104 16.2167 208.566 10.2597C219.224 3.55006 230.207 0 241.443 0C252.679 0 263.662 3.55006 274.32 10.2597C283.782 16.2167 292.919 19 301.803 19C310.688 19 319.825 16.2167 329.287 10.2597C339.945 3.55006 350.928 0 362.164 0C373.4 0 384.383 3.55006 395.041 10.2597C404.503 16.2167 413.641 19 422.525 19C431.409 19 440.546 16.2167 450.009 10.2597C460.667 3.55006 471.65 0 482.886 0V8C474.001 8 464.864 10.7833 455.402 16.7403C444.744 23.4499 433.761 27 422.525 27C411.289 27 400.306 23.4499 389.648 16.7403C380.186 10.7833 371.048 8 362.164 8C353.28 8 344.143 10.7833 334.68 16.7403C324.022 23.4499 313.039 27 301.803 27C290.567 27 279.585 23.4499 268.927 16.7403C259.464 10.7833 250.327 8 241.443 8C232.559 8 223.421 10.7833 213.959 16.7403C203.301 23.4499 192.318 27 181.082 27C169.846 27 158.863 23.4499 148.205 16.7403C138.743 10.7833 129.606 8 120.721 8C111.837 8 102.7 10.7833 93.2376 16.7403C82.5797 23.4499 71.3591 27 60.1231 27C48.8872 27 38.1417 23.4499 27.4838 16.7403C18.0215 10.7833 8.88426 8 0 8V0Z"
					fill="url(#linear-gradient_:Rf9i:)"></path>
				<defs>
					<linearGradient id="linear-gradient_:Rf9i:" x1="73.7411" y1="13.0001" x2="456.719" y2="13.0001"
													gradientUnits="userSpaceOnUse">
						<stop stopColor="#A467D5"></stop>
						<stop offset="0.0001" stopColor="#A467D5"></stop>
						<stop offset="0.285152" stopColor="#D8719D"></stop>
						<stop offset="0.716028" stopColor="#FBBC99"></stop>
						<stop offset="1" stopColor="#A467D5"></stop>
					</linearGradient>
				</defs>
			</svg>
		</>
	);
}
