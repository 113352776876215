import {useCallback, useEffect} from "react";
import {GOOGLE_ACCOUNTS_CLIENT_ID} from "../../config.ts";
import {IdentityProvider} from "@app/apis/accounts.ts";

const getGoogleAccounts = () => window.google?.accounts;

export function useGoogleAuthentication(
	isContextSignIn: boolean,
	handleSignInResult: (idp: IdentityProvider, idToken: string) => void
) {
	useEffect(() => {
		const accounts = getGoogleAccounts();

		if (!accounts) {
			return;
		}

		accounts.id.initialize({
			client_id: GOOGLE_ACCOUNTS_CLIENT_ID,
			callback: (credentials: google.accounts.id.CredentialResponse) => {
				handleSignInResult(IdentityProvider.Google, credentials.credential);
			},
			auto_select: false,
			context: isContextSignIn ? 'signin' : 'signup',
			cancel_on_tap_outside: false,
		});

		return () => {
			accounts.id.cancel();
		}
	}, [handleSignInResult, isContextSignIn]);

	const open = useCallback(async () => {
		if (!getGoogleAccounts()) {
			throw new Error('Google Identity Services library not loaded');
		}

		getGoogleAccounts().id.prompt();
	}, []);

	return {openPrompt: open};
}
