import * as Sentry from '@sentry/browser';

export function log(...args: unknown[]) {
	if (import.meta.env.DEV) {
		console.log(...args);
	}
}

export function error(...args: unknown[]) {
	if (import.meta.env.DEV) {
		console.error(...args);
	}
}

export function captureException(e: unknown) {
	Sentry.captureException(e);
}
